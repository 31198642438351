import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles/topics.css';
import NavbarComponent   from '../__layout/Navbar';
import SideNavComponent   from '../__layout/SideNav';
import BreadcrumbComponent   from '../__layout/Breadcrumb';
import BreadcrumbLoader from './parts/BreadcrumbLoader';
import TopicListsComponent from './parts/TopicListsComponent';
import ViewLessonComponent from './parts/ViewLessonComponent';
import { validateToken } from '../../actions/module/validateAdminActions';
import { fetchSubjects, subjectInfo } from '../../actions/module/subjectActions';
import { fetchTopic, newTopic, updateTopic } from '../../actions/module/topicActions';
import { fetchLesson, fetchLessonInfo, newLesson, updateLesson } from '../../actions/module/lessonActions';

import { uploadFile, getFile } from '../../actions/module/uploadActions';
import { notification } from '../__plugins/noty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faTrashAlt, faPlus, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Modal from '../__layout/Modal';
import { Editor } from '@tinymce/tinymce-react'; 
const { SearchBar, ClearSearchButton  } = Search;

class CreateLessonComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardStatus: false,
            cardActive: "",
            isModalOpen: false,
            subjectId: '',
            subjectName: '',
            topicId: '',
            topicName: '',
            lessonId: '',
            lessonContent: '',
            lessonName: '',
            updateLessonContent: '',
            updateLessonName: '',
            viewLesson: false
        };
        this.showCard = this.showCard.bind(this);
        this.formCreateLesson = this.formCreateLesson.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
    }
    componentDidMount(){
        if (localStorage.getItem("pinnacleAdmin") !== null) {
            this.props.validateToken(this.state).then(tokenValidate => {
                if(tokenValidate.payload.status !== 200){
                    this.props.history.push("/login");
                }else{
                    var url = window.location.href;
                    var ids = url.split("/").slice(-2).join("/");
                    var subjectId = ids.split('/')[0];
                    var topicId = ids.split('/')[1];
                    this.setState({topicId: topicId});
                    this.props.fetchTopic(subjectId).then(response => {
                        response.payload.data.forEach(list => {
                            if(list.id === topicId){
                                this.setState({topicName:list.description})
                            }
                        })
                    });
                    this.props.subjectInfo(subjectId).then( subject => {
                        this.setState({subjectName: subject.payload.data.code})
                    }); 
                }
            });
        }else {
            this.props.history.push("/login");
        }
    }

    componentWillReceiveProps(nextProps){
        
    }
    
    showCard(todo, status, lesson){
        if(status == "show"){
            this.setState({cardStatus: true})
            this.setState({cardActive: todo})
            if(todo == "view"){
                this.props.fetchLessonInfo(lesson.topicId, lesson._id);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }else if(todo == "edit"){
                this.props.fetchLessonInfo(lesson.topicId, lesson._id);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }else{
            this.setState({cardStatus: false})
        }
    }

    onChange(e){
        let subject = JSON.parse(e.target.value);
        this.setState({subjectId: subject.id})
        this.setState({subjectName: subject.name})
        this.props.fetchTopic(subject.id)
        // if(e.target.value == ""){
        // }else{
        // }
    }

    onChangeText(e){
        this.setState({[e.target.name]: e.target.value})
    }


    onEditorChange(evt) {
        this.setState({ lessonContent: evt.target.getContent() });        
    }

    formCreateLesson(e){
        e.preventDefault();
        this.setState({disabled: true})
        let formData = {
            "name": this.state.lessonName,
            "content": this.state.lessonContent
        }
        this.props.newLesson(this.state.topicId, formData).then(res => {
            if(res.payload.result === "success"){
                let lessonId = res.payload.data._id;
                 window.history.back();
                notification("success", `<i class = "fa fa-check"></i> ${res.payload.message}`)
            }else if(res.payload.result === "error"){
                notification("success", `<i class = "fa fa-check"></i> ${res.payload.message}`)
            }else{
                notification("error", `<i class = "fa fa-check"></i> Something went wrong, Please try again`)
            }
            this.setState({lessonContent: ""});
            this.setState({lessonName: ""});
            this.setState({disabled: false})
            this.setState({cardStatus: false})
        });

    }


    render() {
        return (
            <div>
             <SideNavComponent/>
                <div className="main">
                    <NavbarComponent/>
                    <div className="container">
                        <div className = "col">
                            <br/>
                            {this.state.subjectName && this.state.topicName ? 
                                <BreadcrumbComponent 
                                    path = "create-lesson"
                                    subjectName = {this.state.subjectName}
                                    topicName = {this.state.topicName}
                                />
                            : <BreadcrumbLoader/>}
                            
                            <div className = "row">
                            <div className = {this.state.cardStatus == true ? "col-md-7" : "col"}>




                            <div className="card card-custom-border">
                            <div className="card-header">
                                Fill the following fields below
                            </div>
                            <div className="card-body">
                                <form onSubmit = {this.formCreateLesson} id = "createLessonForm">
                                    <div className="form-group">
                                        <p><b> {this.state.subjectName}</b></p>
                                        <p><b> {this.state.topicName}</b></p>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Enter lesson name" name = "lessonName" value = {this.state.lessonName} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                    </div>
                                    <div className="form-group">
                                        <label for="name">Content</label>
                                        {/* <CKEditor
                                            editor={ ClassicEditor }
                                            data=""
                                            onChange={this.onEditorChange}
                                        /> */}
                                        <Editor
                                            initialValue=""
                                            init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'font image underline',
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            // plugins: [
                                            //     'lists link image paste help wordcount'
                                            // ],
                                            toolbar:
                                                'font undo redo | formatselect | bold italic underline backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat image | help'
                                            }}
                                            onChange={this.onEditorChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-block" disabled = {(this.state.disabled)? "disabled" : ""}>
                                        {this.state.disabled ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div> : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            
                                        

                                </div>
                            </div>
                        


                            </div>
                        </div>
                    </div>


                   
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    validateToken: state.validateToken.testData,
    subjectLists: state.subjects.items.data,
    topicLists: state.topics.items.data,
    questionsLists: state.questions.items.data,
    newQuestion: state.questions.question,
    lessonLists: state.lessons.items,
    lessonInfo: state.lessons.info
})

export default connect(mapStateToProps, { 
    validateToken,
    fetchSubjects, 
    subjectInfo,
    fetchTopic,
    fetchLesson,
    fetchLessonInfo,
    newLesson,
    updateLesson
})(CreateLessonComponent);