import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavbarComponent from '../__layout/Navbar';
import SideNavComponent from '../__layout/SideNav';
import { validateToken } from '../../actions/module/validateAdminActions';
import {
  fetchSubjects,
  subjectInfo,
} from '../../actions/module/subjectActions';
import { fetchTopic } from '../../actions/module/topicActions';
import {
  fetchQuestions,
  newQuestion,
  fetchQuestionInfo,
  updateQuestion,
} from '../../actions/module/questionsActions';
import { uploadFileV2 } from '../../actions/module/uploadActionsV2';
import { notification } from '../__plugins/noty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEdit,
  faTrash,
  faTrashAlt,
  faPlus,
  faTimes,
  faInfo,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import './styles/question.css';
import { Editor } from '@tinymce/tinymce-react';

import BreadcrumbComponent from '../__layout/Breadcrumb';
import BreadcrumbLoader from './parts/BreadcrumbLoader';
import BootstrapTable from 'react-bootstrap-table-next';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Modal from '../__layout/Modal';
const { SearchBar, ClearSearchButton } = Search;

class QuestionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardStatus: false,
      cardActive: '',
      isModalOpen: false,
      subjectId: '',
      subjectName: '',
      topicId: '',
      topicName: '',
      questionName: '',
      questionTag: '',
      questionChoice1: 'a',
      questionChoice2: 'b',
      questionChoice3: 'c',
      questionChoice4: 'd',
      questionSolution: '',
      questionAnswer: '',
      updateQuestionName: '',
      updateQuestionTag: '',
      updateQuestionChoice1: 'a',
      updateQuestionChoice2: 'b',
      updateQuestionChoice3: 'c',
      updateQuestionChoice4: 'd',
      updateQuestionSolution: '',
      updateQuestionAnswer: '',
      fileTargetValueQuestion: '',
      disabledQuestion: false,
      fileTargetValueQuestionSolution: '',
      disabledQuestionSolution: false,
    };
    this.showCard = this.showCard.bind(this);
    this.formCreateQuestion = this.formCreateQuestion.bind(this);
    this.formEditQuestion = this.formEditQuestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeTopic = this.onChangeTopic.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.handleUploadQuestionName = this.handleUploadQuestionName.bind(this);
    this.handleUploadQuestionSolution = this.handleUploadQuestionSolution.bind(
      this
    );
    this.onChangeFile = this.onChangeFile.bind(this);
    this.onChangeFileSolution = this.onChangeFileSolution.bind(this);
    this.formUpdateQuestion = this.formUpdateQuestion.bind(this);
    // this.onEditorChange = this.onEditorChange.bind( this );
  }
  componentDidMount() {
    if (localStorage.getItem('pinnacleAdmin') !== null) {
      this.props.validateToken(this.state).then((tokenValidate) => {
        if (tokenValidate.payload.status !== 200) {
          this.props.history.push('/login');
        } else {
          var url = window.location.href;
          var ids = url.split('/').slice(-2).join('/');
          var subjectId = ids.split('/')[0];
          var topicId = ids.split('/')[1];
          this.setState({ subjectId });
          this.setState({ topicId });

          this.props.fetchQuestions(this.state.subjectId, this.state.topicId);
          this.props.fetchTopic(subjectId).then((response) => {
            response.payload.data.forEach((list) => {
              if (list.id === topicId) {
                this.setState({ topicName: list.description });
              }
            });
          });
          this.props.subjectInfo(subjectId).then((subject) => {
            this.setState({ subjectName: subject.payload.data.code });
          });
        }
      });
    } else {
      this.props.history.push('/login');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.questionInfo) {
      this.setState({ disabled: false });
      this.setState({ updateQuestionTag: nextProps.questionInfo.data.tag });
      this.setState({
        updateQuestionName: nextProps.questionInfo.data.question,
      });
      this.setState({
        updateQuestionChoice1: nextProps.questionInfo.data.choices[0],
      });
      this.setState({
        updateQuestionChoice2: nextProps.questionInfo.data.choices[1],
      });
      this.setState({
        updateQuestionChoice3: nextProps.questionInfo.data.choices[2],
      });
      this.setState({
        updateQuestionChoice4: nextProps.questionInfo.data.choices[3],
      });
      this.setState({
        updateQuestionAnswer: nextProps.questionInfo.data.answer,
      });
      this.setState({
        updateQuestionSolution: nextProps.questionInfo.data.solution,
      });
    }
  }

  showCard(todo, status, id) {
    this.setState({ fileTargetValueQuestionSolution: null });
    this.setState({ disabledQuestionSolution: false });

    this.setState({ fileTargetValueQuestion: null });
    this.setState({ disabledQuestion: false });

    if (status == 'show') {
      this.setState({ cardStatus: true });
      this.setState({ cardActive: todo });
      if (todo == 'view') {
        this.props.fetchQuestionInfo(id);
      } else if (todo == 'edit') {
        this.setState({ disabled: true });
        this.props.fetchQuestionInfo(id);
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      this.setState({ cardStatus: false });
    }
  }

  onChange(e) {
    let subject = JSON.parse(e.target.value);
    this.setState({ subjectId: subject.id });
    this.setState({ subjectName: subject.name });
    this.props.fetchTopic(subject.id);
    // if(e.target.value == ""){
    // }else{
    // }
  }

  onChangeText(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeTopic(e) {
    if (e.target.value == '') {
      this.setState({ topicId: '' });
    } else {
      let topic = JSON.parse(e.target.value);
      this.setState({ topicId: topic.id });
      this.setState({ topicName: topic.description });
      this.props.fetchQuestions(this.state.subjectId, topic.id);
    }
  }

  onEditorChange(evt) {
    if (this.state.cardActive === 'create') {
      // this.setState({ questionName: evt.editor.getData() });
      let testt11 = this.editorInstance.save();
    } else if (this.state.cardActive === 'edit') {
      this.setState({ updateQuestionName: evt.editor.getData() });
    }
  }

  validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  formCreateQuestion(e) {
    e.preventDefault();
    this.setState({ disabled: true });
    if (this.state.questionAnswer == '') {
      this.setState({ disabled: false });
      notification(
        'error',
        `<i class = "fa fa-check"></i> Select answer first`
      );
    } else {
      if (this.state.fileTargetValueQuestion) {
        //Upload Question Image First
        this.props
          .uploadFileV2(this.state.fileTargetValueQuestion)
          .then((response) => {
            if (response.payload.result === 'success') {
              this.setState({ questionName: response.payload.data.url });
              //When question image uploaded next process upload solution image
              if (this.state.fileTargetValueQuestionSolution) {
                this.props
                  .uploadFileV2(this.state.fileTargetValueQuestionSolution)
                  .then((response) => {
                    if (response.payload.result === 'success') {
                      this.setState({
                        questionSolution: response.payload.data.url,
                      });
                      //After both uploaded question and solution call api for saving record
                      let formData = {
                        tag: this.state.questionTag,
                        name: this.state.questionName,
                        solution: this.state.questionSolution,
                        choices: [
                          this.state.questionChoice1,
                          this.state.questionChoice2,
                          this.state.questionChoice3,
                          this.state.questionChoice4,
                        ],
                        answer: this.state.questionAnswer,
                        subjectId: this.state.subjectId,
                        topicId: this.state.topicId,
                      };
                      this.props.newQuestion(formData).then((res) => {
                        if (res.payload.result === 'success') {
                          notification(
                            'success',
                            `<i class = "fa fa-check"></i> ${res.payload.message}`
                          );
                          this.props.fetchQuestions(
                            this.state.subjectId,
                            this.state.topicId
                          );
                        } else if (res.payload.result === 'error') {
                          notification(
                            'success',
                            `<i class = "fa fa-check"></i> ${res.payload.message}`
                          );
                        } else {
                          notification(
                            'error',
                            `<i class = "fa fa-check"></i> Something went wrong, Please try again`
                          );
                        }
                        this.setState({ questionName: '' });
                        this.setState({ questionSolution: '' });
                        this.setState({ questionAnswer: '' });
                        this.setState({ disabled: false });
                        this.setState({ cardStatus: false });
                      });
                    } else {
                      notification(
                        'error',
                        `<i class = "fa fa-times"></i> Something went wrong, Please try again`
                      );
                    }
                    this.setState({ disabledQuestionSolution: false });
                  });
              } else {
                //After both uploaded question and solution call api for saving record
                let formData = {
                  tag: this.state.questionTag,
                  name: this.state.questionName,
                  choices: [
                    this.state.questionChoice1,
                    this.state.questionChoice2,
                    this.state.questionChoice3,
                    this.state.questionChoice4,
                  ],
                  answer: this.state.questionAnswer,
                  subjectId: this.state.subjectId,
                  topicId: this.state.topicId,
                };
                this.props.newQuestion(formData).then((res) => {
                  if (res.payload.result === 'success') {
                    notification(
                      'success',
                      `<i class = "fa fa-check"></i> ${res.payload.message}`
                    );
                    this.props.fetchQuestions(
                      this.state.subjectId,
                      this.state.topicId
                    );
                  } else if (res.payload.result === 'error') {
                    notification(
                      'success',
                      `<i class = "fa fa-check"></i> ${res.payload.message}`
                    );
                  } else {
                    notification(
                      'error',
                      `<i class = "fa fa-check"></i> Something went wrong, Please try again`
                    );
                  }
                  this.setState({ questionName: '' });
                  this.setState({ questionSolution: '' });
                  this.setState({ questionAnswer: '' });
                  this.setState({ disabled: false });
                  this.setState({ cardStatus: false });
                });
              }
            } else {
              notification(
                'error',
                `<i class = "fa fa-times"></i> Something went wrong, Please try again`
              );
            }
            this.setState({ disabledQuestion: false });
          });
      } else {
        this.setState({ disabledQuestion: true });
        notification(
          'error',
          `<i class = "fa fa-times"></i> Select question/solution image first`
        );
      }
    }
  }

  formEditQuestion(e) {
    e.preventDefault();
    this.setState({ disabled: true });
    if (this.state.updateQuestionAnswer == '') {
      notification(
        'error',
        `<i class = "fa fa-check"></i> Select answer first`
      );
    } else {
      let formData = {
        tag: this.state.updateQuestionTag,
        choices: [
          this.state.updateQuestionChoice1,
          this.state.updateQuestionChoice2,
          this.state.updateQuestionChoice3,
          this.state.updateQuestionChoice4,
        ],
        answer: this.state.updateQuestionAnswer,
      };
      if (this.state.fileTargetValueQuestion) {
        this.props
          .uploadFileV2(this.state.fileTargetValueQuestion)
          .then((response) => {
            if (response.payload.result === 'success') {
              formData.question = response.payload.data.url;
              this.setState({ updateQuestionName: response.payload.data.url });
              if (this.state.fileTargetValueQuestionSolution) {
                this.props
                  .uploadFileV2(this.state.fileTargetValueQuestionSolution)
                  .then((response) => {
                    if (response.payload.result === 'success') {
                      formData.solution = response.payload.data.url;
                      this.formUpdateQuestion(
                        this.props.questionInfo.data._id,
                        formData
                      );
                    } else {
                      notification(
                        'error',
                        `<i class = "fa fa-times"></i> Something went wrong, Please try again`
                      );
                    }
                    this.setState({ disabledQuestionSolution: false });
                  });
              } else {
                this.formUpdateQuestion(
                  this.props.questionInfo.data._id,
                  formData
                );
              }
            } else {
              notification(
                'error',
                `<i class = "fa fa-times"></i> Something went wrong, Please try again`
              );
            }
            this.setState({ disabledQuestion: false });
          });
      } else if (this.state.fileTargetValueQuestion) {
        this.props
          .uploadFileV2(this.state.fileTargetValueQuestion)
          .then((response) => {
            if (response.payload.result === 'success') {
              formData.question = response.payload.data.url;
              this.formUpdateQuestion(
                this.props.questionInfo.data._id,
                formData
              );
            } else {
              notification(
                'error',
                `<i class = "fa fa-times"></i> Something went wrong, Please try again`
              );
            }
            this.setState({ disabledQuestion: false });
          });
      } else if (this.state.fileTargetValueQuestionSolution) {
        this.props
          .uploadFileV2(this.state.fileTargetValueQuestionSolution)
          .then((response) => {
            if (response.payload.result === 'success') {
              formData.solution = response.payload.data.url;
              this.formUpdateQuestion(
                this.props.questionInfo.data._id,
                formData
              );
            } else {
              notification(
                'error',
                `<i class = "fa fa-times"></i> Something went wrong, Please try again`
              );
            }
            this.setState({ disabledQuestionSolution: false });
          });
      } else {
        this.formUpdateQuestion(this.props.questionInfo.data._id, formData);
      }
    }
  }

  formUpdateQuestion(questionId, formData) {
    this.props.updateQuestion(questionId, formData).then((res) => {
      if (res.payload.result === 'success') {
        notification(
          'success',
          `<i class = "fa fa-check"></i> ${res.payload.message}`
        );
        this.props.fetchQuestions(this.state.subjectId, this.state.topicId);
      } else if (res.payload.result === 'error') {
        notification(
          'success',
          `<i class = "fa fa-check"></i> ${res.payload.message}`
        );
      } else {
        notification(
          'error',
          `<i class = "fa fa-check"></i> Something went wrong, Please try again`
        );
      }
      this.setState({ disabled: false });
      this.setState({ cardStatus: false });
    });
  }

  toggleState = (id) => {
    if (typeof id === 'undefined') {
      this.setState({ isModalOpen: false });
    } else {
      this.setState({ isModalOpen: true });
      this.props.fetchQuestionInfo(id);
    }
  };

  handleEditorChange = (e) => {
    if (this.state.cardActive === 'create') {
      this.setState({ questionName: e.target.getContent() });
    } else if (this.state.cardActive === 'edit') {
      this.setState({ updateQuestionName: e.target.getContent() });
    }
  };

  handleEditorChangeSolution = (e) => {
    if (this.state.cardActive === 'create') {
      this.setState({ questionSolution: e.target.getContent() });
    } else if (this.state.cardActive === 'edit') {
      this.setState({ updateQuestionSolution: e.target.getContent() });
    }
  };

  onChangeFile(event) {
    let file = event.target.files[0];
    if (file == null || file == '') {
    } else {
      this.setState({ fileTargetValueQuestion: file });
    }
  }

  onChangeFileSolution(event) {
    let file = event.target.files[0];
    if (file == null || file == '') {
    } else {
      this.setState({ fileTargetValueQuestionSolution: file });
    }
  }

  handleUploadQuestionName(e) {
    e.preventDefault();
    this.setState({ disabledQuestion: true });
    if (this.state.fileTargetValueQuestion) {
      this.props
        .uploadFileV2(this.state.fileTargetValueQuestion)
        .then((response) => {
          if (response.payload.result === 'success') {
            if (this.state.cardActive === 'create') {
              this.setState({ questionName: response.payload.data.url });
            } else if (this.state.cardActive === 'edit') {
              this.setState({ updateQuestionName: response.payload.data.url });
            }
          } else {
            notification(
              'error',
              `<i class = "fa fa-times"></i> Something went wrong, Please try again`
            );
          }
          this.setState({ disabledQuestion: false });
        });
    } else {
      this.setState({ disabledQuestion: true });
      notification(
        'error',
        `<i class = "fa fa-times"></i> Select file image first`
      );
    }
  }

  handleUploadQuestionSolution(e) {
    e.preventDefault();
    this.setState({ disabledQuestionSolution: true });
    if (this.state.fileTargetValueQuestionSolution) {
      this.props
        .uploadFileV2(this.state.fileTargetValueQuestionSolution)
        .then((response) => {
          if (response.payload.result === 'success') {
            if (this.state.cardActive === 'create') {
              this.setState({ questionSolution: response.payload.data.url });
            } else if (this.state.cardActive === 'edit') {
              this.setState({
                updateQuestionSolution: response.payload.data.url,
              });
            }
          } else {
            notification(
              'error',
              `<i class = "fa fa-times"></i> Something went wrong, Please try again`
            );
          }
          this.setState({ disabledQuestionSolution: false });
        });
    } else {
      this.setState({ disabledQuestionSolution: false });
      notification(
        'error',
        `<i class = "fa fa-times"></i> Select file image first`
      );
    }
  }

  render() {
    let questionsArr = new Array();
    let $this = this;
    if (this.props.questionsLists) {
      this.props.questionsLists.forEach((q) => {
        questionsArr.push({
          tag: q.tag,
          name: (
            <Popup
              trigger={
                <button className="btn btn-secondary"> Show Question</button>
              }
              modal
              closeOnDocumentClick
            >
              <div className="align-center modal-size-custom">
                <h4>Question</h4>
                <hr />
                <img src={q.question} className="img-fluid" />
              </div>
              {/* <div className = "align-center"><img src = {q.question} height = "500px" width = "600px"/></div> */}
            </Popup>
          ),
          solution: (
            <Popup
              trigger={
                <button className="btn btn-secondary"> Show Solution</button>
              }
              modal
              closeOnDocumentClick
            >
              {/* <div className = "align-center"><img src = {q.solution} height = "500px" width = "600px"/></div> */}
              <div className="align-center modal-size-custom">
                <h4>Solution</h4>
                <hr />
                <img src={q.solution} className="img-fluid" />
              </div>
            </Popup>
          ),
          action: (
            <div className="">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.showCard('view', 'show', q._id)}
              >
                {' '}
                <FontAwesomeIcon icon={faEye} />
              </button>{' '}
              &nbsp;
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={() => this.showCard('edit', 'show', q._id)}
              >
                {' '}
                <FontAwesomeIcon icon={faEdit} />
              </button>{' '}
              &nbsp;
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.toggleState(q._id)}
              >
                {' '}
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ),
        });
      });
      // }
    } else {
      questionsArr = [];
    }
    let columns = [
      { dataField: 'tag', text: 'Tag', style: { width: '450px' } },
      { dataField: 'name', text: 'Question', style: { width: '450px' } },
      {
        dataField: 'solution',
        text: 'Solution',
        style: { width: '450px' },
        searchable: true,
      },
      { dataField: 'action', text: 'Action', style: { width: '200px' } },
    ];
    return (
      <div>
        <SideNavComponent />
        <div className="main">
          <NavbarComponent />
          <div className="container">
            <div className="col">
              <br />
              {this.state.topicName && this.state.subjectName ? (
                <BreadcrumbComponent
                  path="questions"
                  subjectName={this.state.subjectName}
                  topicName={this.state.topicName}
                />
              ) : (
                <BreadcrumbLoader />
              )}
              <div className="row">
                <div>
                  {this.state.isModalOpen && (
                    <Modal
                      id="modal"
                      isOpen={this.state.isModalOpen}
                      onClose={this.toggleState}
                      title="Delete"
                      type="delete-question"
                      data={
                        this.props.questionInfo
                          ? this.props.questionInfo.data
                          : ''
                      }
                    >
                      <div className="box-body">
                        Are you sure you want to delete this{' '}
                        <b>
                          {' '}
                          {this.state.questionInfo
                            ? this.state.questionInfo.data.tag
                            : ''}{' '}
                        </b>
                        ?
                      </div>
                    </Modal>
                  )}
                </div>
                {this.state.cardStatus == true ? (
                  <div className="col-md-12">
                    <div className="card card-custom-border">
                      <div className="card-header">
                        {this.state.cardActive == 'create'
                          ? 'Create new question'
                          : ''}
                        {this.state.cardActive == 'edit' ? 'Edit question' : ''}
                        {this.state.cardActive == 'view'
                          ? 'Question Information'
                          : ''}
                        <small className="float-right">
                          <a
                            className="card-link"
                            onClick={this.showCard.bind(this, 'create', 'hide')}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Close
                          </a>
                        </small>
                      </div>
                      <div className="card-body">
                        {this.state.cardActive == 'create' ? (
                          <form
                            onSubmit={this.formCreateQuestion}
                            id="createQuestionForm"
                          >
                            {/* <div className="form-group">
                                                            <p>Selected Subject: {this.state.subjectName}</p>
                                                            <p>Selected Topic: {this.state.topicName}</p>
                                                        </div> */}
                            <div className="form-group">
                              <label for="name">Tag</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter question tag"
                                name="questionTag"
                                value={this.state.questionTag}
                                onChange={this.onChangeText}
                                required
                                disabled={this.state.disabled ? 'disabled' : ''}
                              />
                            </div>
                            <div className="form-group">
                              <label for="name">Title</label>
                              <div class="input-group mb-3">
                                <input
                                  type="file"
                                  class="form-control"
                                  onChange={this.onChangeFile}
                                  disabled={
                                    this.state.disabledQuestion
                                      ? 'disabled'
                                      : ''
                                  }
                                />
                              </div>
                              {this.state.disabledQuestion ? (
                                <div className="spinner-border" role="status">
                                  {' '}
                                  <span className="sr-only">
                                    Loading...
                                  </span>{' '}
                                </div>
                              ) : (
                                ''
                              )}
                              {/* <input type="text" className="form-control" placeholder="Question Image URL" name = "questionName" value = {this.state.questionName} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/> */}
                            </div>
                            {/* <div className="form-group">
                                                            <label for="name">Choices</label>
                                                            <div className = "row">
                                                                <div className = "col-md-6">
                                                                    <input type="text" className="form-control" placeholder="Choice A" name = "questionChoice1" value = {this.state.questionChoice1} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                                </div>
                                                                
                                                                <div className = "col-md-6">
                                                                    <input type="text" className="form-control" placeholder="Choice C" name = "questionChoice3" value = {this.state.questionChoice3} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                                </div>
                                                                <div className = "col-md-6">
                                                                    <input type="text" className="form-control" placeholder="Choice B" name = "questionChoice2" value = {this.state.questionChoice2} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                                </div>
                                                                <div className = "col-md-6">
                                                                    <input type="text" className="form-control" placeholder="Choice D" name = "questionChoice4" value = {this.state.questionChoice4} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                                </div>
                                                            </div>
                                                        </div> */}
                            <div className="form-group">
                              <label for="name">Answer</label>
                              <select
                                className="form-control"
                                name="questionAnswer"
                                onChange={this.onChangeText}
                                disabled={this.state.disabled ? 'disabled' : ''}
                              >
                                <option value=""></option>
                                <option value={this.state.questionChoice1}>
                                  {' '}
                                  {this.state.questionChoice1.toUpperCase()}{' '}
                                </option>
                                <option value={this.state.questionChoice2}>
                                  {' '}
                                  {this.state.questionChoice2.toUpperCase()}{' '}
                                </option>
                                <option value={this.state.questionChoice3}>
                                  {' '}
                                  {this.state.questionChoice3.toUpperCase()}{' '}
                                </option>
                                <option value={this.state.questionChoice4}>
                                  {' '}
                                  {this.state.questionChoice4.toUpperCase()}{' '}
                                </option>
                                {/* {this.state.questionChoice1 === "" ? "" : <option key = {this.state.questionChoice1} value = {this.state.questionChoice1}>{this.state.questionChoice1}</option>}
                                                                {this.state.questionChoice2 === "" ? "" : <option key = {this.state.questionChoice2} value = {this.state.questionChoice2}>{this.state.questionChoice2}</option>}
                                                                {this.state.questionChoice3 === "" ? "" : <option key = {this.state.questionChoice3} value = {this.state.questionChoice3}>{this.state.questionChoice3}</option>}
                                                                {this.state.questionChoice4 === "" ? "" : <option key = {this.state.questionChoice4} value = {this.state.questionChoice4}>{this.state.questionChoice4}</option>} */}
                              </select>
                              {/* <input type="text" className="form-control" placeholder="Enter Answer" name = "questionAnswer" value = {this.state.questionAnswer} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/> */}
                            </div>
                            <div className="form-group">
                              <label for="name">Solution</label>
                              <div class="input-group mb-3">
                                <input
                                  type="file"
                                  class="form-control"
                                  onChange={this.onChangeFileSolution}
                                  disabled={
                                    this.state.disabledQuestionSolution
                                      ? 'disabled'
                                      : ''
                                  }
                                />
                              </div>
                              {this.state.disabledQuestionSolution ? (
                                <div className="spinner-border" role="status">
                                  {' '}
                                  <span className="sr-only">
                                    Loading...
                                  </span>{' '}
                                </div>
                              ) : (
                                ''
                              )}
                              {/* <input type="text" className="form-control" placeholder="Solution Image URL" name = "questionSolution" value = {this.state.questionSolution} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/> */}
                            </div>
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={this.state.disabled ? 'disabled' : ''}
                              >
                                {this.state.disabled ? (
                                  <div className="spinner-border" role="status">
                                    {' '}
                                    <span className="sr-only">
                                      Loading...
                                    </span>{' '}
                                  </div>
                                ) : (
                                  'Submit'
                                )}
                              </button>
                            </div>
                          </form>
                        ) : (
                          ''
                        )}

                        {this.state.cardActive == 'edit' ? (
                          <form
                            onSubmit={this.formEditQuestion}
                            id="createEditForm"
                          >
                            {/* <div className="form-group">
                                                            <p>Selected Subject: {this.state.subjectName}</p>
                                                            <p>Selected Topic: {this.state.topicName}</p>
                                                        </div> */}
                            <div className="form-group">
                              <label for="name">Tag</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter question tag"
                                name="updateQuestionTag"
                                value={this.state.updateQuestionTag}
                                onChange={this.onChangeText}
                                required
                                disabled={this.state.disabled ? 'disabled' : ''}
                              />
                            </div>
                            <div className="form-group">
                              <label for="name">Question</label>
                              <div class="input-group mb-3">
                                <input
                                  type="file"
                                  class="form-control"
                                  onChange={this.onChangeFile}
                                  disabled={
                                    this.state.disabledQuestion
                                      ? 'disabled'
                                      : ''
                                  }
                                />
                              </div>
                              {this.state.disabledQuestion ? (
                                <div className="spinner-border" role="status">
                                  {' '}
                                  <span className="sr-only">
                                    Loading...
                                  </span>{' '}
                                </div>
                              ) : (
                                ''
                              )}

                              {/* <input type="text" className="form-control" placeholder="Choice A" name = "updateQuestionName" value = {this.state.updateQuestionName} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/> */}
                            </div>
                            {/* <div className="form-group">
                                                            <label for="name">Choices</label>
                                                            <div className = "row">
                                                                <div className = "col-md-6">
                                                                    <input type="text" className="form-control" placeholder="Choice A" name = "updateQuestionChoice1" value = {this.state.updateQuestionChoice1} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                                </div>
                                                                <div className = "col-md-6">
                                                                    <input type="text" className="form-control" placeholder="Choice C" name = "updateQuestionChoice3" value = {this.state.updateQuestionChoice3} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                                </div>
                                                                <div className = "col-md-6">
                                                                    <input type="text" className="form-control" placeholder="Choice B" name = "updateQuestionChoice2" value = {this.state.updateQuestionChoice2} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                                 </div>
                                                                <div className = "col-md-6">
                                                                    <input type="text" className="form-control" placeholder="Choice D" name = "updateQuestionChoice4" value = {this.state.updateQuestionChoice4} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                                </div>
                                                            </div>
                                                        </div> */}
                            <div className="form-group">
                              <label for="name">Answer</label>
                              <p>
                                <b>Current Answer:</b>{' '}
                                {this.state.updateQuestionAnswer}{' '}
                              </p>
                              <select
                                className="form-control"
                                name="updateQuestionAnswer"
                                onChange={this.onChangeText}
                                disabled={this.state.disabled ? 'disabled' : ''}
                              >
                                <option value=""></option>
                                {this.state.updateQuestionChoice1 == '' ? (
                                  ''
                                ) : (
                                  <option
                                    key={this.state.updateQuestionChoice1}
                                    value={this.state.updateQuestionChoice1}
                                  >
                                    {this.state.updateQuestionChoice1}
                                  </option>
                                )}
                                {this.state.updateQuestionChoice2 == '' ? (
                                  ''
                                ) : (
                                  <option
                                    key={this.state.updateQuestionChoice2}
                                    value={this.state.updateQuestionChoice2}
                                  >
                                    {this.state.updateQuestionChoice2}
                                  </option>
                                )}
                                {this.state.updateQuestionChoice3 == '' ? (
                                  ''
                                ) : (
                                  <option
                                    key={this.state.updateQuestionChoice3}
                                    value={this.state.updateQuestionChoice3}
                                  >
                                    {this.state.updateQuestionChoice3}
                                  </option>
                                )}
                                {this.state.updateQuestionChoice4 == '' ? (
                                  ''
                                ) : (
                                  <option
                                    key={this.state.updateQuestionChoice4}
                                    value={this.state.updateQuestionChoice4}
                                  >
                                    {this.state.updateQuestionChoice4}
                                  </option>
                                )}
                              </select>
                              {/* <input type="text" className="form-control" placeholder="Enter Answer" name = "questionAnswer" value = {this.state.questionAnswer} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/> */}
                            </div>
                            <div className="form-group">
                              <label for="name">Solution</label>
                              <div class="input-group mb-3">
                                <input
                                  type="file"
                                  class="form-control"
                                  onChange={this.onChangeFileSolution}
                                  disabled={
                                    this.state.disabledQuestionSolution
                                      ? 'disabled'
                                      : ''
                                  }
                                />
                              </div>
                              {this.state.disabledQuestionSolution ? (
                                <div className="spinner-border" role="status">
                                  {' '}
                                  <span className="sr-only">
                                    Loading...
                                  </span>{' '}
                                </div>
                              ) : (
                                ''
                              )}
                              {/* <input type="text" className="form-control" placeholder="Enter solution" name = "updateQuestionSolution" value = {this.state.updateQuestionSolution} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/> */}
                            </div>
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                disabled={this.state.disabled ? 'disabled' : ''}
                              >
                                {this.state.disabled ? (
                                  <div className="spinner-border" role="status">
                                    {' '}
                                    <span className="sr-only">
                                      Loading...
                                    </span>{' '}
                                  </div>
                                ) : (
                                  'Submit'
                                )}
                              </button>
                            </div>
                          </form>
                        ) : (
                          ''
                        )}

                        {this.state.cardActive == 'view' ? (
                          this.props.questionInfo ? (
                            <div>
                              <p>
                                Subject Name:<b> {this.state.subjectName}</b>
                              </p>
                              <p>
                                Topic Name:<b> {this.state.topicName}</b>
                              </p>
                              <p>
                                Question Tag:{' '}
                                <b>{this.props.questionInfo.data.tag}</b>
                              </p>
                              <div class="alert alert-secondary">
                                <img
                                  src={this.props.questionInfo.data.question}
                                  className="img-fluid"
                                />
                                {/* <div dangerouslySetInnerHTML={{ __html: this.props.questionInfo.data.question }} /> */}
                              </div>
                              <p>Choices: </p>
                              <p>
                                <b>
                                  A. {this.props.questionInfo.data.choices[0]}
                                </b>
                              </p>
                              <p>
                                <b>
                                  B. {this.props.questionInfo.data.choices[1]}
                                </b>
                              </p>
                              <p>
                                <b>
                                  C. {this.props.questionInfo.data.choices[2]}
                                </b>
                              </p>
                              <p>
                                <b>
                                  D. {this.props.questionInfo.data.choices[3]}
                                </b>
                              </p>
                              <hr />
                              <p>
                                Answer:{' '}
                                <b>{this.props.questionInfo.data.answer}</b>
                              </p>
                              <hr />
                              <p>
                                Solution:{' '}
                                <img
                                  src={this.props.questionInfo.data.solution}
                                  className="img-fluid"
                                />
                                {/* <div dangerouslySetInnerHTML={{ __html: this.props.questionInfo.data.solution }} /> */}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <i class="fa fa-spinner fa-spin"></i> Loading
                              content...{' '}
                            </div>
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                ) : (
                  ''
                )}

                <div className="col-md-12">
                  <div className="card card-custom-border">
                    <div className="card-header">
                      Content box
                      <small className="float-right">
                        <a
                          className="card-link"
                          onClick={this.showCard.bind(
                            this,
                            'create',
                            'show',
                            ''
                          )}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add new question
                        </a>
                      </small>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          {questionsArr.length > 0 ? (
                            <ToolkitProvider
                              keyField="id"
                              data={questionsArr}
                              columns={columns}
                              search
                            >
                              {(props) => (
                                <div>
                                  <SearchBar {...props.searchProps} />
                                  {/* <ClearSearchButton { ...props.searchProps } /> */}
                                  <hr />
                                  <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory()}
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          ) : (
                            <div class="alert alert-success">
                              <strong>
                                This topic has no question yet.{' '}
                                <a
                                  className="card-link"
                                  onClick={this.showCard.bind(
                                    this,
                                    'create',
                                    'show',
                                    ''
                                  )}
                                >
                                  Add one?
                                </a>
                              </strong>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div class="alert alert-warning">
                    Total Questions: <b>{this.props.questionsLists ? this.props.questionsLists.length : '0'} </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  validateToken: state.validateToken.testData,
  subjectLists: state.subjects.items.data,
  topicLists: state.topics.items.data,
  questionsLists: state.questions.items.data,
  newQuestion: state.questions.question,
  questionInfo: state.questions.info,
  fileUpload: state.fileV2.data,
});

export default connect(mapStateToProps, {
  validateToken,
  fetchSubjects,
  subjectInfo,
  fetchTopic,
  fetchQuestions,
  newQuestion,
  fetchQuestionInfo,
  updateQuestion,
  uploadFileV2,
})(QuestionsComponent);
