import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.css';
import { updatePassword } from '../../../actions/module/frontResetPasswordActions';
import { validateToken } from '../../../actions/module/validateAdminActions';
import { notification } from '../../__plugins/noty';

class FrontResetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      disabled: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmitUpdatePassword = this.onSubmitUpdatePassword.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="main-content ">
        <div className="wrapper fadeInDown">
          <div class="form-content">
            <div className="fadeIn first">
              <br />
              <img
                src="../../img/logo_old.png"
                className="logo"
                id="icon"
                alt="User Icon"
              />
            </div>
            <div className="container">
              <br />
              <form onSubmit={this.onSubmitUpdatePassword}>
                <div className="form-group">
                  <input
                    type="password"
                    id="newPassword"
                    className="form-control fadeIn"
                    name="newPassword"
                    value={this.state.newPassword}
                    placeholder="Enter new password"
                    onChange={this.onChange}
                    disabled={this.state.disabled ? 'disabled' : ''}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    id="confirmPassword"
                    className="form-control fadeIn"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    placeholder="Confirm new password"
                    onChange={this.onChange}
                    disabled={this.state.disabled ? 'disabled' : ''}
                    required
                  />
                </div>
                <div className="form-group">
                  <button className="btn btn-warning " type="submit">
                    {this.state.disabled ? (
                      <div className="spinner-border" role="status">
                        {' '}
                        <span className="sr-only">Loading...</span>{' '}
                      </div>
                    ) : (
                      'Update Password'
                    )}
                  </button>{' '}
                </div>
              </form>
            </div>
            <div className="form-footer">
              <a
                className="underline-hover"
                href="http://www.pinnaclecpareview.ph/"
                target="_blank"
              >
                Go to the Site
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitUpdatePassword(e) {
    e.preventDefault();
    if (this.state.newPassword === this.state.confirmPassword) {
      const urlParams = new URLSearchParams(window.location.search);
      const userToken = urlParams.get('expire');
      if (userToken) {
        this.setState({ disabled: !this.state.disabled });
        const formData = {
          newPassword: this.state.newPassword,
        };
        this.props.updatePassword(userToken, formData).then((res) => {
          if ('name' in res.payload) {
            this.setState({ disabled: !this.state.disabled });
            notification(
              'error',
              `<i class = "fa fa-check"></i> Unable to reset password, please use forgot password again`
            );
          } else {
            notification(
              'success',
              `<i class = "fa fa-check"></i> ${res.payload.message}`
            );
          }
          setTimeout(function () {
            window.location.href = 'http://pinnaclecpareview.ph';
          }, 3000);
        });
      } else {
        notification(
          'error',
          `<i class = "fa fa-check"></i> Unable to reset password.`
        );
        setTimeout(function () {
          window.location.href = 'http://pinnaclecpareview.ph';
        }, 3000);
      }
    } else {
      notification(
        'error',
        `<i class = "fa fa-check"></i> New password and confirm new password not match.`
      );
    }
  }
}

FrontResetPasswordComponent.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  validateToken: state.validateToken.testData,
});

export default connect(mapStateToProps, { validateToken, updatePassword })(
  FrontResetPasswordComponent
);
