import axios from 'axios';
import { ADMIN_UPLOAD_FILE_V2 } from '../types';
import { receiveData } from '../payload'
import { BasePath, clientId, clientSecret, multipartType } from '../env';

export function uploadFileV2(fileData){

    return function(dispatch){

        let headerAdminUser = {
            headers: {
                'x-client-id': clientId,
                'x-client-secret': clientSecret,
                'content-type': multipartType,
                'token': JSON.parse(localStorage.getItem("pinnacleAdmin")).token
                // Access-Control-Allow-Origin
            }
        }
        const formData = new FormData();
        formData.append('file',fileData)
        return axios.post(`${BasePath}/v2/uploads?folder=questions`, formData, headerAdminUser)
        .then((response) => {
            if(response.data){
                return dispatch(receivePostsData(response.data))
            }
        })
        .catch(function (error) {
            let message = error.response.data.message;
            let status = error.response.status;
            return dispatch(receiveData(ADMIN_UPLOAD_FILE_V2, {message, status}))
        })
    }
}


export const receivePostsData = posts => ({
  type: ADMIN_UPLOAD_FILE_V2,
  payload: posts
})
