import React, { Component } from 'react'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../styles/subject.css';
import { fetchSubjects } from '../../../actions/module/subjectActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
class SubjectListsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleCreateSubject = this.handleCreateSubject.bind(this);
        this.handleActionSubject = this.handleActionSubject.bind(this);
        this.handleDeleteSubject = this.handleDeleteSubject.bind(this);
        this.handleShowTopics = this.handleShowTopics.bind(this);
    }

    componentDidMount(){
        // this.props.fetchSubjects();
    }

    handleCreateSubject(e){
        e.preventDefault();
        this.props.onClickCreateSubject();
    }

    handleActionSubject(type, id){
        this.props.onClickViewSubject(type, id);
    }

    handleDeleteSubject(id){
        this.props.onClickDeleteSubject(id)
    }

    handleShowTopics(id){
        this.props.onClickShowTopic(id)
    }
    render() {
      
        return (          
            <div class = "container">
                <div className = "row">
                    <ul class="ds-btn">
                        {this.props.lists ? 
                            this.props.lists.subjects.map( list => {
                                return (
                                    <li>
                                        <label className="btn btn-primary custom-subject-list" title = {list.name}>
                                            <span>
                                                {list.code.length > 15 ? list.code.substr(0, 10) + '...' : list.code}
                                                <br/>
                                                <small>{list.name.length > 15 ? list.name.substr(0, 10) + '...' : list.name}</small><br/>
                                                {/* Link to = {`topics/${list.id}`} */}
                                                <button type="button"  className="btn btn-info btn-sm" onClick={this.handleActionSubject.bind(this, 'view', list.id)}> <FontAwesomeIcon icon = {faEye}/></button> &nbsp;
                                                <button type="button"  className="btn btn-success btn-sm" onClick={this.handleActionSubject.bind(this, 'edit', list.id)}> <FontAwesomeIcon icon = {faEdit}/></button> &nbsp;
                                                <button type="button"  className="btn btn-danger btn-sm" onClick={this.handleDeleteSubject.bind(this, list.id)}> <FontAwesomeIcon icon = {faTrash}/></button> <br/><br/>
                                                <p><Link to = {`topics/${list.id}`}  className="btn btn-warning btn-sm"> Show Topics</Link> </p>
                                                <p><Link to = {`/mock/${list.id}`}  className="btn btn-warning btn-sm"> Create Mock</Link></p>
                                                {/* <button type = "button" onClick = {this.handleShowTopics.bind(this, list.id)}  className="btn btn-warning btn-sm"> Show topics</button> */}
                                                {/* <button type="button"  className="btn btn-danger btn-sm" onClick={() =>  this.toggleState(subj.id)}> <FontAwesomeIcon icon = {faTrash}/></button> */}
                                            </span>
                                        </label>
                                    </li>
                                )
                            })
                        : ""}
                        <li>
                            <button class="btn btn-warning color-secondary custom-subject-list" onClick = {this.handleCreateSubject}>
                                <span> <FontAwesomeIcon icon = {faPlus}/> <br/><small>Add Subject</small></span>
                            </button>         
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

SubjectListsComponent.propTypes = {
    // fetchSubjects: PropTypes.func.isRequired,
    // newSubject: PropTypes.func.isRequired,
    // subjectUpdate: PropTypes.func.isRequired,
    // subjectDelete: PropTypes.func.isRequired,
    // uploadFile: PropTypes.func.isRequired,
    // getFile: PropTypes.func.isRequired,
    // subjects:  PropTypes.array.isRequired,
    // fileUploaded: PropTypes.object,
    // subjectInfo: PropTypes.func.isRequired,
    // subjectInfoProps: PropTypes.object,
    // addedSubject: PropTypes.object,
    // updateSubject: PropTypes.object,
    // deleteSubjectProps: PropTypes.object
};

const mapStateToProps = state => ({
    lists: state.subjects.items.data
})

export default connect(mapStateToProps, { 
    fetchSubjects
})(SubjectListsComponent);