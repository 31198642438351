import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createPost } from '../actions/module/postActions';

class PostForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            body: ''
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    render() {
        return (
            <div>
                <h1>Add post</h1>
                <form onSubmit = {this.onSubmit}>
                    <div>
                        <label>Title</label> <br/>
                        <input type = "text" name = "title" value = {this.state.title} onChange = {this.onChange}/>
                    </div>
                    <br/>
                    <div>
                        <label>Body</label> <br/>
                        <textarea name = "body" value = {this.state.body} onChange = {this.onChange}/>
                    </div>
                    <br/>
                    <button type = "submit">Submit</button>
                </form>
            </div>
        );
    }

    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    onSubmit(e){
        e.preventDefault();
        const post = { 
            title: this.state.title,
            body: this.state.body
        }
        this.props.createPost(post);
        
        
    }
}

PostForm.propTypes = {
    createPost: PropTypes.func.isRequired
};

export default connect(null, { createPost })(PostForm);
