import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import '../styles/topics.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faTrashAlt, faPlus, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next'
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar, ClearSearchButton  } = Search;

class TopicListsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };

        this.handleCreate = this.handleCreate.bind(this);
        this.handleShowLesson = this.handleShowLesson.bind(this);
        this.handleCreateLesson = this.handleCreateLesson.bind(this);
    }
    componentDidMount(){
        
    }

    handleAction(type, id){
        this.props.onClickShowCard(type, id)
    }

    handleDeleteTopic(topic){
        this.props.onClickDeleteTopic(topic)
    }

    handleCreate(e){
        e.preventDefault();
        this.props.onClickCreate();
    }

    handleShowLesson(topic){
        this.props.onClickShowLesson(topic);
    }

    handleCreateLesson(){
        this.props.onClickCreateLesson();
    }

  
    render() {
        return (
            <div>
                <ul class="ds-btn">
                {this.props.topics ? 
                    this.props.topics.map( list => {
                        return (<li>
                            <label className="btn btn-primary custom-topic-list">
                                <span>
                                    {/* {list.code}<br/> */}
                                    <div className = "">
                                        <small className = "" title = {list.description}>{list.description.length > 20 ? list.description.substr(0, 20) + '...' : list.description}</small>
                                    </div>
                                    <br/>
                                    <button type="button"  className="btn btn-success btn-sm" onClick={this.handleAction.bind(this, 'edit', list)}> <FontAwesomeIcon icon = {faEdit}/></button> &nbsp;
                                    <button type="button"  className="btn btn-danger btn-sm" onClick={this.handleDeleteTopic.bind(this, list)}> <FontAwesomeIcon icon = {faTrash}/></button> <br/><br/>
                                    <p><Link to = {`/questions/${list.subjectId}/${list.id}`}  className="btn btn-info btn-sm"> Questions </Link></p>
                                    {list.lessons.length > 0 ? 
                                        <p><Link to = {`/lessons/${list.subjectId}/${list.id}`}  className="btn btn-info btn-sm"> Show Lesson</Link></p>
                                        // <button type="button"  className="btn btn-info btn-sm" onClick={this.handleShowLesson.bind(this, list)}> <FontAwesomeIcon icon = {faEye}/> Show Lesson</button>
                                    :
                                        <p><Link to = {`/lesson/create/${list.subjectId}/${list.id}`}  className="btn btn-secondary btn-sm">  <FontAwesomeIcon icon = {faPlus}/> Add Lesson</Link></p>
                                        // <button type="button"  className="btn btn-warning btn-sm" onClick={this.handleCreateLesson.bind(this)}> <FontAwesomeIcon icon = {faTrash}/></button>
                                    }
                                    
                                </span>
                            </label>
                        </li>)
                    })
                : ""}
                <li>
                    <button class="btn btn-warning color-secondary custom-topic-list" onClick = {this.handleCreate}>
                        <span> <FontAwesomeIcon icon = {faPlus}/> <br/><small>Add Topic</small></span>
                    </button>         
                </li>
                </ul>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    
})

export default connect(mapStateToProps, { 

})(TopicListsComponent);