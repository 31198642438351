import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles/topics.css';
import NavbarComponent   from '../__layout/Navbar';
import SideNavComponent   from '../__layout/SideNav';
import TopicListsComponent from './parts/TopicListsComponent';
import LessonComponent from './LessonComponent';
import { validateToken } from '../../actions/module/validateAdminActions';
import { fetchSubjects, subjectInfo } from '../../actions/module/subjectActions';
import { fetchTopic, newTopic, updateTopic } from '../../actions/module/topicActions';
import { fetchLesson, fetchLessonInfo, newLesson, updateLesson } from '../../actions/module/lessonActions';

import { uploadFile, getFile } from '../../actions/module/uploadActions';
import { notification } from '../__plugins/noty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faTrashAlt, faPlus, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import Modal from '../__layout/Modal';
import BreadcrumbComponent   from '../__layout/Breadcrumb';
import BreadcrumbLoader from './parts/BreadcrumbLoader';

class TopicComponent extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            "subjectId": "",
            "cardStatus": false,
            "disabled": false,
            "isModalOpen": false,
            "createTopicName": "",
            "editTopicName": "",
            "subjectName": "",
            "topicId": "",
            "topicName": "",
            "lessonName": "",
            "lessonContent": "",
            "topicInfo": "",
            "viewLesson": false
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeForm = this.onChangeForm.bind(this);
        this.formCreateTopic = this.formCreateTopic.bind(this);
        this.formUpdateTopic = this.formUpdateTopic.bind(this);
        this.setShowCardView = this.setShowCardView.bind(this);
        this.setShowToggle = this.setShowToggle.bind(this);
        this.showLesson = this.showLesson.bind(this);
        // this.showCreateLesson = this.showCreateLesson.bind(this);
    }
    componentDidMount(){
        this._isMounted = true;
        if (localStorage.getItem("pinnacleAdmin") !== null) {
            this.props.validateToken(this.state).then(tokenValidate => {
                if(tokenValidate.payload.status !== 200){
                    this.props.history.push("/login");
                }else{
                    var url = window.location.href;
                    var id = url.substring(url.lastIndexOf('/') + 1);
                    this.setState({subjectId: id});
                    this.props.subjectInfo(id).then( subject => {
                        this.setState({subjectName: subject.payload.data.code})
                        this.props.fetchTopic(id);
                    });
                    
                    
                }
            });
        }else {
            this.props.history.push("/login");
        }
    }

    componentWillReceiveProps(nextProps){
    }




    showCard(todo, status, id){
        if(status == "show"){
            this.setState({cardStatus: true})
            this.setState({cardActive: todo})
            if(todo == "view"){
                this.setState({disabled: true});
                // this.props.subjectInfo(id);
            }else if(todo == "edit"){
                this.setState({disabled: false});
                this.setState({topicId: id.id});
                this.setState({editTopicName: id.description});
                this.setState({updateLoad: false});
                // this.props.subjectInfo(id);
            }
        }else{
            this.setState({cardStatus: false})
        }
    }

    onChange(e){
        if(e.target.value == ""){
            this.setState({subjectId: ""})
        }else{
            let subject = JSON.parse(e.target.value);
            this.setState({subjectId: subject.id})
            this.setState({subjectName: subject.name})
            this.props.fetchTopic(subject.id)
        }
    }

    onChangeForm(e){
        this.setState({[e.target.name]: e.target.value})
    }

    formCreateTopic(e){
        e.preventDefault();
        this.setState({disabled: true})
        let formData = {
            "description": this.state.createTopicName
        }
        this.props.newTopic(this.state.subjectId, formData).then( data => {
            this.setState({disabled: false})
            this.setState({createTopicName: ''})
            if(data.payload.result === "success"){
                this.props.fetchTopic(this.state.subjectId);
                notification("success", `<i class = "fa fa-check"></i> ${data.payload.message}`)
            }else if(data.payload.result === "failed"){
                notification("error", `<i class = "fa fa-remove"></i> ${data.payload.message}`)
            }else{
                notification("error", `<i class = "fa fa-remove"></i> Something went wrong, Please try again`)
            }
        })
        
    }

    formUpdateTopic(e){
        e.preventDefault();
        if(this.state.subjectId === ""){
            notification("error", `<i class = "fa fa-remove"></i> Select subject first`)
        }else{
            this.setState({disabled: true})
            let formData = {
                "description": this.state.editTopicName,
                "subjectId": this.state.subjectId,
                "isArchive": false
            }
            this.props.updateTopic(this.state.subjectId, this.state.topicId, formData).then( data => {
                this.setState({disabled: false})
                this.setState({editTopicName: ''})
                if(data.payload.result === "success"){
                    this.props.fetchTopic(this.state.subjectId);
                    notification("success", `<i class = "fa fa-check"></i> ${data.payload.message}`)
                }else if(data.payload.result === "failed"){
                    notification("error", `<i class = "fa fa-remove"></i> ${data.payload.message}`)
                }else{
                    notification("error", `<i class = "fa fa-remove"></i> Something went wrong, Please try again`)
                }
            })
        }
    }

    toggleState = (topic) => {
        if (typeof topic === 'undefined') {
            this.setState({ isModalOpen: false});
        }else{
            this.setState({topicId: topic.id})
            this.setState({topicName: topic.description})
            this.setState({ isModalOpen: !this.state.isModalOpen});
        }
    }

    setShowToggle(topic){
        this.toggleState(topic)   
    }

    setShowCardView(type, id){
        this.showCard(type, 'show', id)
    }

    showLesson(topic){
        this.setState({viewLesson: true})
        this.setState({topicId: topic.id})
        this.setState({topicName: topic.description})
        this.props.fetchLesson(topic.id).then(response => {
            this.setState({lessonName: response.payload.data[0].name})
            this.setState({lessonContent: response.payload.data[0].content})
        });
    }

    render() {
        return (
            <div>
             <SideNavComponent/>
                <div className="main">
                    <NavbarComponent/>
                    <div className="container">
                        <div className = "col">
                            <br/>
                            {this.state.subjectName ? 
                                <BreadcrumbComponent 
                                    path = "topics"
                                    subjectName = {this.state.subjectName}
                                />
                             : 
                                <BreadcrumbLoader/>
                            }
                            <div className = "row">
                            <div className = {this.state.cardStatus == true ? "col-md-7" : "col"}>
                                    <div className="">
                                        <TopicListsComponent 
                                            topics = {this.props.topicLists} 
                                            onClickCreate = {this.showCard.bind(this, 'create', 'show', '')} 
                                            onClickShowCard = {this.setShowCardView}
                                            onClickDeleteTopic = {this.setShowToggle}
                                            onClickShowLesson = {this.showLesson}
                                        />
                                    </div>
                                    {this.state.isModalOpen && (
                                        <Modal 
                                            id="modal" 
                                            isOpen={this.state.isModalOpen} 
                                            onClose={this.toggleState} 
                                            title = "Delete" 
                                            type = "delete-topic" 
                                            topicId = {this.state.topicId} 
                                            subjectId = {this.state.subjectId}
                                        >
                                            <div className="box-body">Are you sure you want to delete this <b> {this.state.topicName} </b>?</div>
                                        </Modal>
                                        )}
                            </div>
                                { this.state.cardStatus == true ?
                                    <div className = "col-md-5">
                                        <div className="card border-primary">
                                            <div className="card-header">
                                                {this.state.cardActive == "view" ? "Topic Information" : ""}
                                                {this.state.cardActive == "create" ? "Create new topic": ""}
                                                {this.state.cardActive == "edit" ? "Edit topic": ""}
                                                <small className = "float-right">
                                                    <a className = "card-link" onClick = {this.showCard.bind(this, 'create', 'hide')}>
                                                        <FontAwesomeIcon icon = {faTimes}/> Close
                                                    </a>
                                                </small>
                                            </div>
                                            <div className="card-body">
                                                {this.state.cardActive == "create" ?
                                                    <form onSubmit = {this.formCreateTopic} id = "createTopicForm">
                                                        <div className="form-group">
                                                            <label for="code">Name</label>
                                                            <input type="text" className="form-control" placeholder="Enter topic name" name = "createTopicName"  value = {this.state.createTopicName} onChange = {this.onChangeForm} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                        </div>
                                                        
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-primary btn-block" disabled = {(this.state.disabled)? "disabled" : ""}>
                                                            {this.state.disabled ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div> : 'Submit'}
                                                            </button>
                                                        </div>
                                                    </form>
                                                : ""}

                                                {this.state.cardActive == "edit" ?
                                                    <form onSubmit = {this.formUpdateTopic} id = "editTopicForm">
                                                        {/* <div className="form-group">
                                                            <label for="code"><b>{this.state.subjectName}</b></label>
                                                        </div> */}
                                                        <div className="form-group">
                                                            <label for="code">Name</label>
                                                            <input type="text" className="form-control" placeholder="Enter topic name" name = "editTopicName"  value = {this.state.editTopicName} onChange = {this.onChangeForm} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                                        </div>
                                                        
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-primary btn-block" disabled = {(this.state.disabled)? "disabled" : ""}>
                                                            {this.state.disabled ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div> : 'Submit'}
                                                            </button>
                                                        </div>
                                                    </form>
                                                : ""}
                                            </div>
                                        </div>
                                    </div>
                                : "" }
                            </div>
                            <div class="alert alert-warning">
                                Total Topics: <b>{this.props.topicLists ? this.props.topicLists.length : '0'} </b>
                            </div>
                        </div>
                    </div>


                    {/* VIEW LESSON */}
                    {this.state.viewLesson ? 
                        <div className="container">
                            <div className = "col">
                                <div className="row subject-well">
                                    <LessonComponent 
                                        topicId = {this.state.topicId} 
                                        topicName = {this.state.topicName} 
                                        subjectId = {this.state.subjectId}
                                        lessonName = {this.state.lessonName}
                                        lessonContent = {this.state.lessonContent}
                                        onClickCreateLesson = {this.showCreateLesson}
                                    />
                                </div>
                            </div>
                        </div>
                    : ""}

                    {/* ADD LESSON */}
                    {this.state.viewLesson ? 
                        "" 
                    : 
                        ""
                    }
                    

                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    validateToken: state.validateToken.testData,
    subjectLists: state.subjects.items.data,
    topicLists: state.topics.items.data,
    lessonLists: state.lessons.items
})

export default connect(mapStateToProps, { 
    validateToken,
    fetchSubjects,
    fetchTopic,
    newTopic,
    updateTopic,
    fetchLesson,
    fetchLessonInfo,
    subjectInfo
})(TopicComponent);