import React, { Component } from 'react'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux';
import { validateToken } from '../../actions/module/validateAdminActions';

class BreadcrumbComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: false,
          items: [],
          title: "",
          subjectName: this.props.subjectName
        };
      }

      componentDidMount(){
        if(this.props.path === "subject"){
            this.setState({title: 'Subject Management'})
            var joined = this.state.items.concat(
                {"name": "Dashboard", path: "/", isClickable: true},
                {"name": "Subjects", path: "/subjects", isClickable: false}
            );
            this.setState({ items: joined })
        }
        if(this.props.path === "topics"){
            this.setState({title: 'Topic Management'})
            var url = window.location.href;
            var id = url.substring(url.lastIndexOf('/') + 1);
            var joined = this.state.items.concat(
                {"name": "Dashboard", path: "/", isClickable: true},
                {"name": `Subjects (${this.state.subjectName})`, path: "/subjects", isClickable: true},
                {"name": `Topics`, path: `/topics/${id}`, isClickable: false}
            );
            this.setState({ items: joined })
        }
        if(this.props.path === "questions"){
            this.setState({title: 'Question Management'})
            var url = window.location.href;
            var ids = url.split("/").slice(-2).join("/");
            var subjectId = ids.split('/')[0];
            var topicId = ids.split('/')[1];
            var joined = this.state.items.concat(
                {"name": "Dashboard", path: "/", isClickable: true},
                {"name": `Subjects (${this.props.subjectName})`, path: "/subjects", isClickable: true},
                {"name": `Topics (${this.props.topicName})`, path: `/topics/${subjectId}`, isClickable: true},
                {"name": `Questions`, path: `/questions/${subjectId}/${topicId}`, isClickable: false}
            );
            this.setState({ items: joined })
        }

        if(this.props.path === "lessons"){
            this.setState({title: 'Lesson Management'})
            var url = window.location.href;
            var ids = url.split("/").slice(-2).join("/");
            var subjectId = ids.split('/')[0];
            var topicId = ids.split('/')[1];
            var joined = this.state.items.concat(
                {"name": "Dashboard", path: "/", isClickable: true},
                {"name": `Subjects (${this.props.subjectName})`, path: "/subjects", isClickable: true},
                {"name": `Topics (${this.props.topicName})`, path: `/topics/${subjectId}`, isClickable: true},
                {"name": `Lessons`, path: `/lessons/${subjectId}/${topicId}`, isClickable: false}
            );
            this.setState({ items: joined })
        }

        if(this.props.path === "create-lesson"){
            this.setState({title: 'Create Lesson'})
            var url = window.location.href;
            var ids = url.split("/").slice(-2).join("/");
            var subjectId = ids.split('/')[0];
            var topicId = ids.split('/')[1];
            var joined = this.state.items.concat(
                {"name": "Dashboard", path: "/", isClickable: true},
                {"name": `Subjects (${this.props.subjectName})`, path: "/subjects", isClickable: true},
                {"name": `Topics (${this.props.topicName})`, path: `/topics/${subjectId}`, isClickable: true},
                {"name": `Lesson / Create`, path: `/lesson/create/${subjectId}/${topicId}`, isClickable: false}
            );
            this.setState({ items: joined })
        }

        if(this.props.path === "mock"){
            this.setState({title: 'Mock Management'})
            var url = window.location.href;
            var id = url.substring(url.lastIndexOf('/') + 1);
            var joined = this.state.items.concat(
                {"name": "Dashboard", path: "/", isClickable: true},
                {"name": `Subjects (${this.state.subjectName})`, path: "/subjects", isClickable: true},
                {"name": `Mock`, path: `/mock/${id}`, isClickable: false}
            );
            this.setState({ items: joined })
        }
      }

    
    render() {
        
        return (
            <div>
                <h2>{this.state.title}</h2>
                <ol className="breadcrumb">
                    {this.state.items.map( list =>
                        <li className="breadcrumb-item">
                            {list.isClickable ? <Link to = {list.path}>{list.name}</Link> : list.name}                            
                        </li>
                    )}
                </ol>
            </div>
          );
    }
}


const mapStateToProps = state => ({
//   validateToken: state.validateToken.testData
})
export default connect(mapStateToProps, { validateToken })(BreadcrumbComponent);
