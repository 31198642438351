import React, { Component } from 'react'

import Posts from '../../components/Posts';
export default class MainContent extends Component {
    render() {
        return (
            <div>
                <Posts/>
            </div>
        )
    }
}
