import axios from 'axios';
import { FRONT_UPDATE_PASSWORD } from '../types';
import { receiveData } from '../payload';
import { BasePath, clientId, clientSecret } from '../env';

export function updatePassword(token, formData) {
  return function (dispatch) {
    let headerAdminUser = {
      headers: {
        'x-client-id': clientId,
        'x-client-secret': clientSecret,
        token: token,
      },
    };
    return axios
      .post(`${BasePath}/auth/password/update`, formData, headerAdminUser)
      .then((response) => {
        return dispatch(receiveData(FRONT_UPDATE_PASSWORD, response.data));
      })
      .catch(function (error) {
        return dispatch(receiveData(FRONT_UPDATE_PASSWORD, error));
      });
  };
}
