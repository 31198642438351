import React, { Component } from 'react'
import ContentLoader from "react-content-loader";

export default class BreadcrumbLoader extends Component {
    render() {
        return (
            <ContentLoader 
                speed={2}
                width={609}
                height={100}
                viewBox="0 0 609 100"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                >
                <rect x="9" y="19" rx="0" ry="0" width="248" height="16" /> 
                <rect x="8" y="55" rx="0" ry="0" width="591" height="33" /> 
                <rect x="8" y="1169" rx="0" ry="0" width="680" height="2" /> 
                <rect x="19" y="1140" rx="0" ry="0" width="428" height="14" /> 
                <rect x="7" y="1535" rx="0" ry="0" width="680" height="2" /> 
                <rect x="10" y="1176" rx="0" ry="0" width="477" height="321" /> 
                <rect x="18" y="1506" rx="0" ry="0" width="342" height="14" />
            </ContentLoader>
        )
    }
}
