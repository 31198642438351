import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import './styles.css';
import { validateToken } from '../../../actions/module/validateAdminActions';
import { fetchSubjects } from '../../../actions/module/subjectActions';
import { fetchTopic } from '../../../actions/module/topicActions';
import { fetchLesson, fetchLessonInfo, newLesson, updateLesson } from '../../../actions/module/lessonActions';
import { fetchQuestions, newQuestion } from '../../../actions/module/questionsActions';
import { notification } from '../../__plugins/noty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faTrashAlt, faPlus, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
// import CKEditor from 'ckeditor4-react';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from '@tinymce/tinymce-react'; 

class EditLessonComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardStatus: false,
            cardActive: "",
            isModalOpen: false,
            subjectId: '',
            subjectName: '',
            lessonId: '',
            topicId: '',
            topicName: '',
            lessonContent: '',
            lessonName: '',
            updateLessonContent: '',
            updateLessonName: ''
        }
        this.formCreateLesson = this.formCreateLesson.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
        this.formSubmitCreate = this.formSubmitCreate.bind(this);
        // this.onChangeFile = this.onChangeFile.bind(this);
        // this.onEditorChange = this.onEditorChange.bind( this );
    }
    componentDidMount(){
        var url = window.location.href;
        var ids = url.split("/").slice(-2).join("/");
        var topicId = ids.split('/')[1];
        this.props.fetchLesson(topicId).then(response => {
            this.setState({lessonId:response.payload.data[0]._id})
            this.setState({updateLessonName:response.payload.data[0].name})
            this.setState({updateLessonContent:response.payload.data[0].content})
        });
    }

    componentWillReceiveProps(nextProps){

    }

    onChange(e){
        let subject = JSON.parse(e.target.value);
        this.setState({subjectId: subject.id})
        this.setState({subjectName: subject.name})
        this.props.fetchTopic(subject.id)
        // if(e.target.value == ""){
        // }else{
        // }
    }

    onChangeText(e){
        this.setState({[e.target.name]: e.target.value})
    }

    onEditorChange(evt) {
        this.setState({ lessonContent: evt.target.getContent() });
    }


    toggleState = (topicId, lessonId) => {
        if (typeof topicId === 'undefined') {
            this.setState({ isModalOpen: false});
        }else{
            this.props.fetchLessonInfo(topicId, lessonId);
            this.setState({ isModalOpen: !this.state.isModalOpen});
        }
    };

    formSubmitCreate(e){
        e.preventDefault();
        this.setState({disabled: true})
        let formData = {
            "name": this.state.lessonName,
            "content": this.state.lessonContent
        }
        this.props.handleSubmitCreate(formData)
    }

    render() {
        return (
            <div>
                 <div className = "row">
                    <div className = "col-md-12">
                        <div className="card card-custom-border">
                            <div className="card-header">
                                Edit lesson
                            </div>
                            <div className="card-body">
                                <form onSubmit = {this.formSubmitCreate} id = "createLessonForm">
                                    <div className="form-group">
                                        <p><b> {this.state.subjectName}</b></p>
                                        <p><b> {this.state.topicName}</b></p>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Enter lesson name" name = "lessonName" value = {this.state.lessonName} onChange = {this.onChangeText} required disabled = {(this.state.disabled)? "disabled" : ""}/>
                                    </div>
                                    <div className="form-group">
                                        <label for="name">Content</label>
                                        {/* <CKEditor
                                            editor={ ClassicEditor }
                                            data=""
                                            onChange={this.onEditorChange}
                                        /> */}
                                        <Editor
                                            initialValue=""
                                            init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'font image underline',
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            // plugins: [
                                            //     'lists link image paste help wordcount'
                                            // ],
                                            toolbar:
                                                'font undo redo | formatselect | bold italic underline backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat image | help'
                                            }}
                                            onChange={this.onEditorChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-block" disabled = {(this.state.disabled)? "disabled" : ""}>
                                        {this.state.disabled ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div> : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            
                                        

                                </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
            </div>
        )
    }
}






const mapStateToProps = state => ({
    validateToken: state.validateToken.testData,
    subjectLists: state.subjects.items.data,
    topicLists: state.topics.items.data,
    questionsLists: state.questions.items.data,
    newQuestion: state.questions.question,
    lessonLists: state.lessons.items,
    lessonInfo: state.lessons.info
})

export default connect(mapStateToProps, { 
    validateToken,
    fetchSubjects, 
    fetchTopic,
    fetchQuestions,
    newQuestion,
    fetchLesson,
    fetchLessonInfo,
    newLesson,
    updateLesson
})(EditLessonComponent);
