import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles/mock.css';
import NavbarComponent   from '../__layout/Navbar';
import SideNavComponent   from '../__layout/SideNav';
import BreadcrumbComponent   from '../__layout/Breadcrumb';
import BreadcrumbLoader from './parts/BreadcrumbLoader';
import { validateToken } from '../../actions/module/validateAdminActions';
import { fetchSubjects, subjectInfo } from '../../actions/module/subjectActions';
import { fetchQuestions } from '../../actions/module/questionsActions';
import { fetchMock, fetchMockInfo, fetchMockQuestionsToAdd,  newMockExam } from '../../actions/module/mockActions';
import { notification } from '../__plugins/noty';
import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faTrashAlt, faPlus, faTimes, faMinus, faArrowUp, faPaperPlane, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import BootstrapTable from 'react-bootstrap-table-next'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Modal from '../__layout/Modal';
import { CSSTransition } from "react-transition-group";
import SlideToggle from 'react-slide-toggle';
import { thisExpression } from '@babel/types';

const { SearchBar, ClearSearchButton  } = Search;

class MockExamComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardStatus: false,
            cardActive: "",
            isModalOpen: false,
            modalTitle: '',
            subjectId: '',
            subjectName: '',
            topicId: '',
            topicName: '',
            lessonContent: '',
            lessonName: '',
            updateLessonContent: '',
            updateLessonName: '',
            showList: false,
            selectedQuestions: [],
            availableQuestionsToAdd: [],
            mockSetDataToRemove: [],
            mockSetDataToAdd: [],
            itemsQuestionsPerSet: [],
            setNumber: '',
            totalMockSet: 0,
            totalMockQuestionPerSet: 0,
            setNumberPerView: '',

        }
        this.showCard = this.showCard.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleAddMockExam = this.handleAddMockExam.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        if (localStorage.getItem("pinnacleAdmin") !== null) {
            this.props.validateToken(this.state).then(tokenValidate => {
                if(tokenValidate.payload.status !== 200){
                    this.props.history.push("/login");
                }else{
                    this.props.fetchMock();
                    var url = window.location.href;
                    var id = url.substring(url.lastIndexOf('/') + 1);
                    this.setState({subjectId: id});
                    this.props.subjectInfo(id).then( subject => {
                        this.setState({subjectName: subject.payload.data.code})
                    });
                }
            });
        }else {
            this.props.history.push("/login");
        }
    }

    componentWillReceiveProps(nextProps){
        const totalMockData = new Array();
        if(nextProps.mockLists){
            nextProps.mockLists.map( mock => {
                if(this.state.subjectId == mock.subjectId){
                    totalMockData.push(mock.subjectId)
                }
            })
            this.setState({totalMockSet: totalMockData.length})
        }
        if(nextProps.mockInfo) {
            const totalQuestionPerSet = new Array();
            const itemsQuestionsPerSet = new Array();
            const _this = this;
            this.setState({selectedQuestions: []})
            this.setState({itemsQuestionsPerSet: []})
            this.setState({mockSetDataToRemove: []})
            this.setState({mockSetDataToAdd: []})
            nextProps.mockInfo.data.forEach( q => {
                if(q.setNumber == this.state.setNumberPerView){
                    if(q.questions.length > 0) {
                        q.questions.forEach(qPerSet => {
                            totalQuestionPerSet.push(qPerSet)
                            itemsQuestionsPerSet.push(qPerSet)
                        })
                    }
                }
            });
            this.setState({totalMockQuestionPerSet: totalQuestionPerSet.length})
            this.setState({itemsQuestionsPerSet: this.state.itemsQuestionsPerSet.concat(itemsQuestionsPerSet)})
        }
    }

    switch = () => {
        this.setState(prevState => ({
          showList: !prevState.showList
        }));
      };
    
    listSwitch = () => {
        this.setState(state => ({
            highlightedHobby: !state.highlightedHobby
        }));
    };

    handleChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    showCard(todo, status, id, viewMockSetNumber){
        if(status == "show"){
            this.setState({cardStatus: true})
            this.setState({cardActive: todo})
            this.setState({selectedQuestions: []})
            this.setState({availableQuestionsToAdd: []})
            this.setState({mockSetDataToRemove: []})
            this.setState({mockSetDataToAdd: []})
            this.setState({itemsQuestionsPerSet: []})
            // this.props.fetchSubjects()
            this.props.fetchQuestions(this.state.subjectId, "")
            if(todo == 'create') {
            } else if(todo == "view"){
                this.setState({setNumberPerView:viewMockSetNumber})
                this.props.fetchMockInfo(id);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            } else if(todo == "remove-questions-per-mock"){
                this.setState({setNumberPerView:viewMockSetNumber})
                this.props.fetchMockInfo(id);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            } else if(todo == "add-question-per-mock"){
                this.setState({setNumberPerView:viewMockSetNumber})
                this.props.fetchMockInfo(id);
                this.props.fetchMockQuestionsToAdd(id, this.state.subjectId, viewMockSetNumber);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }else{
            this.setState({cardStatus: false})
        }
    }

    onChange(e){
        let subject = JSON.parse(e.target.value);
        this.setState({subjectId: subject.id})
        this.setState({subjectName: subject.name})
        this.props.fetchQuestions(subject.id, "")
    }


    
    handleAddMockExam(e){
        e.preventDefault();
        if(this.state.selectedQuestions.length > 0){
            let formData = {
                "subjectId": this.state.subjectId,
                "questions": this.state.selectedQuestions,
                "setNumber": this.state.setNumber
            }
            this.props.newMockExam(formData).then(data => {
                if(data.payload.result === "success"){
                    this.props.fetchMock();
                    this.setState({selectedQuestions: []})
                    notification("success", `<i class = "fa fa-check"></i> ${data.payload.message}`)
                    this.setState({disabled: false});
                    this.setState({cardStatus: false});
                }else if(data.payload.result === "failed"){
                    notification("error", `<i class = "fa fa-warning"></i> ${data.payload.error}`)
                }else{
                    notification("error", `<i class = "fa fa-warning"></i> Something went wrong please try agin`)
                }
            })
        }else{
            notification("error", `<i class = "fa fa-check"></i> Select questions first`)
        }
    }

    btnSelectQuestion(question){
        var selected = this.state.selectedQuestions;
        var joined = selected.concat(question._id);
        if(selected.includes(question._id)){
            var index = selected.indexOf(question._id);
            if (index > -1) {
                selected.splice(index, 1);
                this.setState({ selectedQuestions: selected })
            }
        }else{
            this.setState({ selectedQuestions: joined })
        }
    }

    btnSelectQuestionToRemove(question){
        var selected = this.state.selectedQuestions;
        var joined = selected.concat(question._id);
        if(selected.includes(question._id)){
            var index = selected.indexOf(question._id);
            if (index > -1) {
                selected.splice(index, 1);
                this.setState({ selectedQuestions: selected })
            }
        }else{
            this.setState({ selectedQuestions: joined })
        }
    }

    toggleState = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen});
    };

    submitRemoveQuestion(mockSetInfo){
        if(this.state.selectedQuestions.length > 0){
            mockSetInfo.questionsToRemove = this.state.selectedQuestions;
            this.setState({mockSetDataToRemove: this.state.mockSetDataToRemove.concat(mockSetInfo)})
            this.setState({modalTitle: "Remove questions in mock set"});
            this.setState({ isModalOpen: !this.state.isModalOpen});
        } else {
            notification("error", `<i class = "fa fa-warning"></i> Select question(s) to remove`)
        }
    }

    submitUpdateQuestion(mockSetInfo){
        if(this.state.selectedQuestions.length > 0){
            mockSetInfo.questionsToAdd = this.state.selectedQuestions;
            this.setState({mockSetDataToAdd: this.state.mockSetDataToAdd.concat(mockSetInfo)})
            this.setState({modalTitle: "Add questions in mock set"});
            this.setState({ isModalOpen: !this.state.isModalOpen});
        } else {
            notification("error", `<i class = "fa fa-warning"></i> Select question(s) to remove`)
        }
    }

    render() {
        let mockListsArr = new Array();
        if(this.props.mockLists){
            if(this.props.mockLists.length > 0){
                this.props.mockLists.forEach( mock => {
                    if(this.state.subjectId == mock.subjectId){
                        mockListsArr.push({
                            name: "Set Number: " + mock.setNumber,
                            createdAt: <Moment format="MMMM D YYYY">{mock.createdAt}</Moment>,
                            action: <div className = "">
                                        <button type="button"  className="btn btn-primary btn-sm" onClick={() =>  this.showCard('view', 'show', mock.subjectId, mock.setNumber)} title = "View mockset"> <FontAwesomeIcon icon = {faEye}/></button> &nbsp;
                                        <button type="button"  className="btn btn-success btn-sm" onClick={() =>  this.showCard('add-question-per-mock', 'show', mock.subjectId, mock.setNumber)} title = "Add question(s) per mock set"> <FontAwesomeIcon icon = {faPlus}/></button> &nbsp;
                                        <button type="button"  className="btn btn-warning btn-sm" onClick={() =>  this.showCard('remove-questions-per-mock', 'show', mock.subjectId, mock.setNumber)} title = "Remove question(s) per mock set"> <FontAwesomeIcon icon = {faMinus}/></button> &nbsp;
                                        {/* <button type="button"  className="btn btn-danger btn-sm" onClick={() =>  this.toggleState(lesson.topicId, lesson._id)}> <FontAwesomeIcon icon = {faTrash}/></button> */}
                                    </div>
                        })
                    }
                })
            }
        }else{
            mockListsArr = [];
        }
        let columns = [
            { dataField: 'name', text: 'Set Number',   style: { width: '600px' }, searchable: true},
            { dataField: 'createdAt', text: 'Date created',   style: { width: '600px' }, searchable: true},
            { dataField: 'action', text: 'Action', style: { width: '200px' } }
        ]
        
        
        return (
            <div>
                <SideNavComponent/>
                <div className="main">
                    <NavbarComponent/>
                    <div className="container">
                        <div className = "col">
                            <br/>
                            {this.state.subjectName ? 
                                <BreadcrumbComponent 
                                    path = "mock"
                                    subjectName = {this.state.subjectName}
                                />
                             : 
                                <BreadcrumbLoader/>
                            }
                            <div className = "row">
                                { this.state.cardStatus == true ?
                                    <div className = "col-md-12">
                                        <div className="card card-custom-border">
                                            <div className="card-header">
                                                {this.state.cardActive == "create" ? "Create new mock exam": ""}
                                                {this.state.cardActive == "remove-questions-per-mock" ? "Remove Question(s) Per Mock Set": ""}
                                                {this.state.cardActive == "add-question-per-mock" ? "Add Question(s) Per Mock Set": ""}
                                                {this.state.cardActive == "view" ? "View Mock Information" : ""}
                                                <small className = "float-right">
                                                    <a className = "card-link" onClick = {this.showCard.bind(this, 'create', 'hide', '')}>
                                                        <FontAwesomeIcon icon = {faTimes}/> Close
                                                    </a>
                                                </small>
                                            </div>
                                            <div className="card-body">
                                                {this.state.cardActive == "create" ? 
                                                    <div>
                                                        <form onSubmit = {this.handleAddMockExam}>
                                                            <p>Add Question</p> <hr/>
                                                            <div className = "form-group">
                                                                <label>Set Number:</label>
                                                                <input 
                                                                    type = "number" 
                                                                    className = "form-control" 
                                                                    placeholder = "Set Number"
                                                                    disabled = {(this.state.disabled)? "disabled" : ""}
                                                                    value = {this.state.setNumber}
                                                                    onChange = {this.handleChange}
                                                                    name = "setNumber"
                                                                />
                                                            </div>
                                                            <div class="alert alert-warning">
                                                            Selected: <b>{this.state.selectedQuestions ? this.state.selectedQuestions.length : '0'} </b> / Total : <b>{this.props.questionsLists ? this.props.questionsLists.length : '0'}</b>
                                                            </div>
                                                                {this.props.questionsLists ?
                                                                    this.props.questionsLists.map( q => 
                                                                        <button
                                                                            key = {q._id} 
                                                                            type="button" 
                                                                            className= {this.state.selectedQuestions.length > 0 ? 
                                                                                this.state.selectedQuestions.includes(q._id) ? 
                                                                                    "btn btn-success btn-block question-list" : 
                                                                                    "btn btn-secondary btn-block question-list" 
                                                                                : "btn btn-secondary btn-block question-list"
                                                                            }
                                                                            disabled = {(this.state.disabled)? "disabled" : ""}
                                                                            onClick = {this.btnSelectQuestion.bind(this, q)}
                                                                        >
                                                                            {this.state.selectedQuestions.length > 0 ? this.state.selectedQuestions.includes(q._id) ? <i class="fa fa-check"></i> : "" : ""} {q.tag}
                                                                        </button>
                                                                    )
                                                                : ""}
                                                                
                                                                <hr/>
                                                                <div className="form-group">
                                                                    <button type="submit" className="btn btn-primary btn-block" disabled = {(this.state.disabled)? "disabled" : ""}>
                                                                    {this.state.disabled ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div> : 'Add Mock Exam'}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                    </div>
                                                : ""}

                                                {this.state.cardActive == "remove-questions-per-mock" ?  
                                                    this.props.mockInfo ? 
                                                    <div>
                                                        <p>Total Questions: <b>{this.state.totalMockQuestionPerSet}</b></p>
                                                        <p>Click question tags you want to remove in this mock set: <b>{ this.state.setNumberPerView }</b></p>
                                                        <hr/>
                                                        {this.props.mockInfo.data.map(q =>
                                                               
                                                               q.setNumber == this.state.setNumberPerView ? 
                                                               q.questions.length > 0 ? 
                                                                q.questions.map(qPerSet => {
                                                                    return (
                                                                        <button
                                                                            key = {qPerSet._id} 
                                                                            type="button" 
                                                                            className= {this.state.selectedQuestions.length > 0 ? 
                                                                                this.state.selectedQuestions.includes(qPerSet._id) ? 
                                                                                    "btn btn-danger btn-block question-list" : 
                                                                                    "btn btn-success btn-block question-list" 
                                                                                : "btn btn-success btn-block question-list"
                                                                            }
                                                                            disabled = {(this.state.disabled)? "disabled" : ""}
                                                                            onClick = {this.btnSelectQuestionToRemove.bind(this, qPerSet)}
                                                                        >
                                                                            {this.state.selectedQuestions.length > 0 ? this.state.selectedQuestions.includes(qPerSet._id) ? <i class="fa fa-remove"></i> : "" : ""} {qPerSet.tag}
                                                                        </button>
                                                                    )
                                                                })
                                                               : ""
                                                            : ""
                                                        )}
                                                        <hr/>
                                                        {this.props.mockInfo.data.map(q =>
                                                            q.setNumber == this.state.setNumberPerView ? 
                                                               <button className = "btn btn-primary" disabled = {(this.state.disabled)? "disabled" : ""} onClick = {this.submitRemoveQuestion.bind(this, q)}> <FontAwesomeIcon icon = {faPaperPlane} /> Update Mock Set</button>
                                                            : ""
                                                        )}
                                                        
                                                    </div>
                                                    : ""
                                                : ""}

                                                {this.state.cardActive == "add-question-per-mock" ? 
                                                    <div> 
                                                        {this.props.mockInfo ? 
                                                        <div>
                                                            <p>Total Uploaded: <b>{this.state.totalMockQuestionPerSet}</b></p>
                                                            <p>List of current questions</p>
                                                            <hr/>
                                                            <div>
                                                            <ul class="list-group scroll-questions">
                                                                {this.props.mockInfo.data.map(q =>
                                                                    q.setNumber == this.state.setNumberPerView ? 
                                                                        q.questions.length > 0 ? 
                                                                            q.questions.map(qPerSet => {
                                                                                return (
                                                                                    <li class="list-group-item list-group-item-action">{qPerSet.tag}</li>
                                                                                )
                                                                            })
                                                                        : ""
                                                                    : ""
                                                                )}
                                                            </ul>
                                                            </div>
                                                        </div>
                                                        : ""}
                                                        <hr/>
                                                        <br/>
                                                        <h4>Add questions</h4>
                                                        <p>Click question tags you want to add in this mock set: <b>{ this.state.setNumberPerView }</b></p>
                                                        <div>
                                                            {this.props.availableQuestions ?
                                                                this.props.availableQuestions.map( q => 
                                                                    <button
                                                                        key = {q._id} 
                                                                        type="button" 
                                                                        className= {this.state.selectedQuestions.length > 0 ? 
                                                                            this.state.selectedQuestions.includes(q._id) ? 
                                                                                "btn btn-success btn-block question-list" : 
                                                                                "btn btn-secondary btn-block question-list" 
                                                                            : "btn btn-secondary btn-block question-list"
                                                                        }
                                                                        disabled = {(this.state.disabled)? "disabled" : ""}
                                                                        onClick = {this.btnSelectQuestion.bind(this, q)}
                                                                    >
                                                                        {this.state.selectedQuestions.length > 0 ? this.state.selectedQuestions.includes(q._id) ? <i class="fa fa-check"></i> : "" : ""} {q.tag}
                                                                    </button>
                                                                )
                                                            : ""}
                                                            <hr/>
                                                            <div class="alert alert-warning">
                                                                Selected: <b>{this.state.selectedQuestions ? this.state.selectedQuestions.length : '0'} </b> / Total : <b>{this.props.availableQuestions ? this.props.availableQuestions.length : '0'}</b>
                                                            </div>
                                                            {this.props.mockInfo ? 
                                                                this.props.mockInfo.data.map(q =>
                                                                    q.setNumber == this.state.setNumberPerView ? 
                                                                    <button className = "btn btn-primary" disabled = {(this.state.disabled)? "disabled" : ""} onClick = {this.submitUpdateQuestion.bind(this, q)}> <FontAwesomeIcon icon = {faPlusCircle} /> Add selected questions(s)</button>
                                                                    : ""
                                                                ) 
                                                            : ""}
                                                        </div>
                                                    </div>
                                                : ""}

                                                {this.state.cardActive == "view" ?  
                                                    this.props.mockInfo ? 
                                                        <div>
                                                            {/* <p>Subject: {this.props.mockInfo.data.subject}</p> */}
                                                            <p>Total Questions: <b>{this.state.totalMockQuestionPerSet}</b></p>
                                                            {this.props.mockInfo.data.map(q =>
                                                               
                                                               q.setNumber == this.state.setNumberPerView ? 
                                                               q.questions.length > 0 ? 
                                                                q.questions.map(qPerSet => {
                                                                    return (
                                                                        <div>
                                                                        <div className="list-body container q-list">
                                                                            <br/>
                                                                            <p>Tag: <b> {qPerSet.tag} </b></p>
                                                                            <b>Question:</b> <img src = {qPerSet.question} className = "img-fluid"/>
                                                                            <hr/>
                                                                            <p>Answer: <b> {qPerSet.answer.toUpperCase()} </b></p>
                                                                            <b>Solution:</b> <img src = {qPerSet.solution} className = "img-fluid"/>
                                                                            <hr/>
                                                                        </div>
                                                                        <br/><br/>
                                                                        </div>
                                                                    )
                                                                })
                                                               : ""
                                                                // q.questions.forEach( qPerSet => {
                                                                //     "aaa"
                                                                // })
                                                                : ""
                                                            )}
                                                            <hr/>
                                                        </div>
                                                    : ""
                                                : "" }
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                : "" }
                                

                                <div className = "col-md-12">
                                    <div className="card card-custom-border">
                                        <div className="card-header">
                                            Content box
                                            <small className = "float-right">
                                                <a className = "card-link" onClick = {this.showCard.bind(this, 'create', 'show', '')}><FontAwesomeIcon icon = {faPlus}/> Add new mock</a>
                                            </small>
                                        </div>
                                        <div className="card-body">
                                            <div className = "row">
                                                </div>
                                                <div class = "container">
                                                    <div class=" card">
                                                        <div class="card-body">    
                                                            {mockListsArr.length > 0 ? 
                                                                <ToolkitProvider
                                                                keyField="id"
                                                                data={ mockListsArr }
                                                                columns={ columns }
                                                                search
                                                                >
                                                                {
                                                                    props => (
                                                                    <div>
                                                                        <SearchBar { ...props.searchProps } />
                                                                        {/* <ClearSearchButton { ...props.searchProps } /> */}
                                                                        <hr />
                                                                        <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }/>
                                                                    </div>
                                                                    )
                                                                }
                                                                </ToolkitProvider>                                                            
                                                             : 
                                                             <div class="alert alert-success">
                                                                <strong>Mock exam is empty. <a className = "card-link" onClick = {this.showCard.bind(this, 'create', 'show', '')}>Add one?</a></strong>
                                                              </div>
                                                             }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="alert alert-warning">
                                        Total Mock Set: <b>{this.state.totalMockSet} </b>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.isModalOpen && (
                            <Modal id="modal" isOpen={this.state.isModalOpen} onClose={this.toggleState} title = {this.state.modalTitle} type = {this.state.cardActive === "remove-questions-per-mock" ? "mock-set-remove-question" : "mock-set-add-question"} data = {this.state.cardActive === "remove-questions-per-mock" ? this.state.mockSetDataToRemove : this.state.mockSetDataToAdd}>
                                <div className="box-body">Are you sure you want to continue?</div>
                            </Modal>
                        )}
                    </div>
                </div>
        )
    }
}






const mapStateToProps = state => ({
    validateToken: state.validateToken.testData,
    subjectLists: state.subjects.items.data,
    mockLists: state.mock.items.data,
    mockInfo: state.mock.info,
    availableQuestions: state.mock.availableQuestions,
    questionsLists: state.questions.items.data
})

export default connect(mapStateToProps, { 
    validateToken,
    fetchMock,
    fetchMockInfo,
    fetchSubjects,
    subjectInfo,
    fetchQuestions,
    newMockExam,
    fetchMockQuestionsToAdd
})(MockExamComponent);
