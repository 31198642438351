import React, { Component } from 'react'
import { connect } from 'react-redux';
import './styles/navbar.css';
import { notification } from '../__plugins/noty';
import { validateToken } from '../../actions/module/validateAdminActions';
import '../__layout/styles/loading.css';

class NavbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: false,
          isLogout: false
        };
        this.logOut = this.logOut.bind(this);
      }

    
    render() {
      let _this = this;
        return (
            <div>
              {this.state.isLogout ? <div className="loading">&#8230;</div> : ""}
              <nav class="navbar navbar-icon-top navbar-expand-lg navbar-dark bg-dark">
                
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav ml-auto">
                    {/* <li class="nav-item">
                      <a class="nav-link" href="#">
                        <i class="fa fa-bell">
                          <span class="badge badge-info">0</span>
                        </i>
                        Notification
                      </a>
                    </li> */}
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <i class="fa fa-user"></i> Profile
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href = "#" onClick = {this.logOut}>
                        <i class="fa fa-cog"></i> Logout
                      </a>
                    </li>
                  </ul>
                </div> 
              </nav>
            </div>
          );
    }

    
    logOut() {
      localStorage.clear();
      this.setState({isLogout: true})
      setTimeout(function(){ window.location.href = "/login" }, 500);
    }
}


const mapStateToProps = state => ({
  validateToken: state.validateToken.testData
})
export default connect(mapStateToProps, { validateToken })(NavbarComponent);
