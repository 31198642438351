import React, { Component } from 'react'
import { connect } from 'react-redux';
import '../styles/lesson.css';
import { validateToken } from '../../../actions/module/validateAdminActions';
import { fetchSubjects } from '../../../actions/module/subjectActions';
import { fetchTopic } from '../../../actions/module/topicActions';
import { fetchLesson, fetchLessonInfo, newLesson, updateLesson } from '../../../actions/module/lessonActions';
import { fetchQuestions, newQuestion } from '../../../actions/module/questionsActions';
import { notification } from '../../__plugins/noty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash, faTrashAlt, faPlus, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import EditLessonComponent from './EditLessonComponent';
import CreateLessonComponent from './CreateLessonComponent1';

class LessonComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardStatus: false,
            cardActive: "",
            isModalOpen: false,
            subjectId: '',
            subjectName: '',
            topicId: '',
            topicName: '',
            lessonContent: '',
            lessonName: '',
            updateLessonContent: '',
            updateLessonName: '',
            isEdit: false
        }
        this.showCard = this.showCard.bind(this);
        this.formCreateLesson = this.formCreateLesson.bind(this);
        this.formUpdateLesson = this.formUpdateLesson.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeTopic = this.onChangeTopic.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        // this.onEditorChange = this.onEditorChange.bind( this );
    }
    componentDidMount(){
    }

    componentWillReceiveProps(nextProps){
        
    }

    showCard(todo, status, lesson){
        if(status == "show"){
            this.setState({cardStatus: true})
            this.setState({cardActive: todo})
            if(todo == "view"){
                this.props.fetchLessonInfo(lesson.topicId, lesson._id);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }else if(todo == "edit"){
                this.props.fetchLessonInfo(lesson.topicId, lesson._id);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }else{
            this.setState({cardStatus: false})
        }
    }

    onChange(e){
        let subject = JSON.parse(e.target.value);
        this.setState({subjectId: subject.id})
        this.setState({subjectName: subject.name})
        this.props.fetchTopic(subject.id)
        // if(e.target.value == ""){
        // }else{
        // }
    }

    onChangeText(e){
        this.setState({[e.target.name]: e.target.value})
    }

    onChangeTopic(e){
        if(e.target.value == ""){
            this.setState({topicId: ""})
        }else{
            let topic = JSON.parse(e.target.value);
            this.setState({topicId: topic.id})
            this.setState({topicName: topic.description})
            this.props.fetchLesson(topic.id)
        }
    }

    onEditorChange(evt) {
        if(this.state.cardActive === 'create'){
            this.setState({ lessonContent: evt.target.getContent() });
        }else if(this.state.cardActive === 'edit'){
            this.setState({ updateLessonContent: evt.target.getContent() });
        }
        
    }

    formCreateLesson(formData){
        this.setState({disabled: true})
        this.props.newLesson(this.state.topicId, formData).then(res => {
            if(res.payload.result === "success"){
                notification("success", `<i class = "fa fa-check"></i> ${res.payload.message}`)
                this.props.fetchLesson(this.state.topicId)
            }else if(res.payload.result === "error"){
                notification("success", `<i class = "fa fa-check"></i> ${res.payload.message}`)
            }else{
                notification("error", `<i class = "fa fa-check"></i> Something went wrong, Please try again`)
            }
            this.setState({lessonContent: ""});
            this.setState({lessonName: ""});
            this.setState({disabled: false})
            this.setState({cardStatus: false})
        });

    }

    formUpdateLesson(lessonId, formData){
        this.props.updateLesson(this.props.topicId, lessonId, formData).then(res => {
            if(res.payload.result === "success"){
                notification("success", `<i class = "fa fa-check"></i> ${res.payload.message}`)
                this.props.fetchLesson(this.state.topicId)
                window.history.back();

            }else if(res.payload.result === "error"){
                notification("success", `<i class = "fa fa-check"></i> ${res.payload.message}`)
            }else{
                notification("error", `<i class = "fa fa-check"></i> Something went wrong, Please try again`)
            }
            this.setState({updateLessonContent: ""});
            this.setState({updateLessonName: ""});
            this.setState({disabled: false})
            this.setState({cardStatus: false})
        })
    }

    handleDelete(){
        this.props.onClickDelete();
    }
    
    handleEdit(){
        this.setState({isEdit: true})
    } 

    render() {
       
        return (
            <div> 
                <div className="container">
                    <div className = "col-md-12">
                        <p>
                            Topic Name: 
                            <b>{this.props.topicName} </b>
                            <div class="float-right">
                                <button className = "btn btn-success" onClick = {this.handleEdit}> <FontAwesomeIcon icon = {faEdit}/> Edit </button> &nbsp;
                                <button className = "btn btn-danger" onClick = {this.handleDelete}> <FontAwesomeIcon icon = {faTrash}/>  Delete </button>
                            </div>
                        </p> 
                            <div>
                                <p>Lesson Name: <b>{this.props.lessonName} </b></p>
                                <p>Content: </p>
                                <div class="alert alert-secondary alert-lesson-content">
                                    <div dangerouslySetInnerHTML={{ __html: this.props.lessonContent }} />
                                </div>
                            </div>
                        <div>
                            {this.state.isEdit ? 
                                <EditLessonComponent
                                    handleSubmitUpdate = {this.formUpdateLesson}
                                />
                            : 
                                ""
                            }

                            {this.state.isCreate ? 
                                // <CreateLessonComponent
                                //     handleSubmitCreate = {this.formCreateLesson}
                                // />
                                ""
                            : 
                                ""
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}






const mapStateToProps = state => ({
    validateToken: state.validateToken.testData,
    subjectLists: state.subjects.items.data,
    topicLists: state.topics.items.data,
    questionsLists: state.questions.items.data,
    newQuestion: state.questions.question,
    lessonLists: state.lessons.items,
    lessonInfo: state.lessons.info
})

export default connect(mapStateToProps, { 
    validateToken,
    fetchSubjects, 
    fetchTopic,
    fetchQuestions,
    newQuestion,
    fetchLesson,
    fetchLessonInfo,
    newLesson,
    updateLesson
})(LessonComponent);
