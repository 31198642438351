import axios from 'axios';
import { FETCH_MOCK, MOCK_INFO, NEW_MOCK_EXAM, UPDATE_MOCK_EXAM, MOCK_QUESTIONS_PER_SET_INFO } from '../types';
import { receiveData } from '../payload'
import { BasePath, clientId, clientSecret } from '../env';
import { removeDuplicates } from '../helpers/arrayDuplicate'
export function fetchMock(){
    return function(dispatch){
        let headerAdminUser = {
            headers: {
                'x-client-id': clientId,
                'x-client-secret': clientSecret,
                'token': JSON.parse(localStorage.getItem("pinnacleAdmin")).token
            }
        }
        return axios.get(`${BasePath}/mock`, headerAdminUser)
        .then((response) => {
            return dispatch(receiveData(FETCH_MOCK, response.data))
        })
        .catch(function (error) {
            return dispatch(receiveData(FETCH_MOCK, error))
        })
    }
}

export function fetchMockInfo(id){
    return function(dispatch){
        let headerAdminUser = {
            headers: {
                'x-client-id': clientId,
                'x-client-secret': clientSecret,
                'token': JSON.parse(localStorage.getItem("pinnacleAdmin")).token
            }
        }
        return axios.get(`${BasePath}/mock/subjects/${id}`, headerAdminUser)
        .then((response) => {
            return dispatch(receiveData(MOCK_INFO, response.data))
        })
        .catch(function (error) {
            return dispatch(receiveData(MOCK_INFO, error))
        })
    }
}


export function newMockExam(formData){
    return function(dispatch){
        let headerAdminUser = {
            headers: {
                'x-client-id': clientId,
                'x-client-secret': clientSecret,
                'token': JSON.parse(localStorage.getItem("pinnacleAdmin")).token
            }
        }
        return axios.post(`${BasePath}/mock`, formData, headerAdminUser)
        .then((response) => {
            return dispatch(receiveData(NEW_MOCK_EXAM, response.data))
        })
        .catch(function (error) {
            return dispatch(receiveData(NEW_MOCK_EXAM, error.response.data))
        })
    }
}

export function updateMock(mockSetId, formData){
    return function(dispatch){
        let headerAdminUser = {
            headers: {
                'x-client-id': clientId,
                'x-client-secret': clientSecret,
                'token': JSON.parse(localStorage.getItem("pinnacleAdmin")).token
            }
        }
        return axios.put(`${BasePath}/mock/${mockSetId}`, formData, headerAdminUser)
        .then((response) => {
            return dispatch(receiveData(UPDATE_MOCK_EXAM, response.data))
        })
        .catch(function (error) {
            return dispatch(receiveData(UPDATE_MOCK_EXAM, error))
        })
    }
}

export function fetchMockQuestionsToAdd(id, subjectId, mockSetNumber){
    return function(dispatch){
        let headerAdminUser = {
            headers: {
                'x-client-id': clientId,
                'x-client-secret': clientSecret,
                'token': JSON.parse(localStorage.getItem("pinnacleAdmin")).token
            }
        }
        return axios.get(`${BasePath}/mock/subjects/${id}`, headerAdminUser)
        .then((response) => {
            const res = response.data;
            res.data.forEach( mockSet => {
                if(mockSet.setNumber === mockSetNumber) {
                    axios.get(`${BasePath}/questions?subjectId=${subjectId}`, headerAdminUser).then( (qLists) => {
                        var qToDisplay = removeDuplicates(qLists.data.data, mockSet.questions);
                        return dispatch(receiveData(MOCK_QUESTIONS_PER_SET_INFO, qToDisplay))
                    })
                }
            })
            
        })
        .catch(function (error) {
            return dispatch(receiveData(MOCK_QUESTIONS_PER_SET_INFO, error))
        })
    }
}

// export function deleteLesson(topicId, lessonId){
//     return function(dispatch){
//         let headerAdminUser = {
//             headers: {
//                 'x-client-id': 'Jeyk',
//                 'x-client-secret': 'Qwe12345',
//                 'token': JSON.parse(localStorage.getItem("pinnacleAdmin")).token
//             }
//         }
//         return axios.delete(`${BasePath}/topics/${topicId}/lessons/${lessonId}`, headerAdminUser)
//         .then((response) => {
//             return dispatch(receiveData(LESSON_DELETE, response.data))
//         })
//         .catch(function (error) {
//             return dispatch(receiveData(LESSON_DELETE, error))
//         })
//     }
// }